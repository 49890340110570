/* eslint-disable no-nested-ternary */
import React, { useEffect, useMemo, useRef, useState, useCallback } from "react";
import { Paper, TableContainer, TableSortLabel, Button } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TextField from "@material-ui/core/TextField";
import useFetch from "../../utils/useFetch";
import { openDVImagesPage, openDVOutletPage } from "../../utils/helpers";

const MARGINBOTTOM = 20;
const NAVBAR_HEIGHT = 55 + 46 + 24;

/**
 * List of the last three months names, including the current.
 * Example: ['January', 'February', 'March']
 * Note that the current month is the current UTC month.
 */
const lastThreeMonths = (() => {
  const months = [];

  const date = new Date();
  for (let i = 2; i >= 0; i--) {
    const monthIndex = (date.getUTCMonth() - i + 12) % 12;
    const pastDate = new Date(date.getFullYear(), monthIndex);
    months.push(pastDate.toLocaleString("default", { month: "long" }));
  }

  return months;
})();


type CustomTableColumnType = {
  name: string,
  formatText?: (data: any) => string,
  createStyle?: (data: any) => object,
  key?: string,
  align: "left" | "right" | "inherit" | "center" | "justify",
  shouldFilter?: boolean,
  isButton?: boolean,
  onClick?: any,
  buttonText?: string
};

const DataValidity = (props) => {
  const [heights, setHeights] = useState({
    customers: 0,
    kitrons: 0,
    agents: 0,
  });
  const [search, setSearch] = useState("");
  const [searchFilter, setSearchFilter] = useState({
    key: "dv_responsible",
    value: ""
  });
  // All of these hooks are only needed for supporting small screens, they don't affect functionality at all
  const [cssTrick, enableCssTrick] = useState(false);
  const titleRef = useRef(null);
  const customersDvRef = useRef(null);
  const accuracyAssessmentRef = useRef(null);

  // Search by DV responsible
  useEffect(() => { setSearchFilter({ key: "dv_responsible", value: search.trim() }); }, [search]);

  // Height setting
  useEffect(() => {
    const handleResize = () => {
      let availableHeight = (window.innerHeight - NAVBAR_HEIGHT) -
        titleRef.current.clientHeight - 8 -
        customersDvRef.current.clientHeight - 8 -
        accuracyAssessmentRef.current.clientHeight -
        MARGINBOTTOM * 2;

      // Enable CSS magic for small screen sizes
      if (availableHeight < 300) {
        availableHeight = 500;
        enableCssTrick(true);
      }
      else
        enableCssTrick(false);


      setHeights({
        customers: availableHeight * 0.6,
        kitrons: availableHeight * 0.4,
        agents: availableHeight * 0.4,
      });
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Formatting and styling functions
  const formatDate = (date) => {
    if (date) return date.split("/")[2] + "." + date.split("/")[1] + "." + date.split("/")[0];
    return "NA";
  };
  const formatDvImages = ({ approved, total }) => `${approved}/${total}`;

  // Column definitions
  const columns: Record<string, Array<CustomTableColumnType>> = {
    customers: [
      {
        name: "Property Name",
        key: "property_name",
        align: "left",
        shouldFilter: true
      },
      {
        name: "Outlet Name",
        key: "name",
        align: "left",
        shouldFilter: true
      },
      {
        name: "Data Validity",
        key: "dv_date",
        align: "right",
        shouldFilter: true,
        formatText: formatDate
      },
      {
        name: "Oldest Unlabelled",
        key: "oldest_unlabelled",
        align: "right",
        shouldFilter: true,
        formatText: formatDate
      },
      {
        name: "DV status",
        key: "dv_images",
        align: "left",
        formatText: formatDvImages,
        createStyle: ({ dv_done }) => ({"color": dv_done ? "green" : "red"})
      },
      {
        name: "Outliers",
        key: "outliers",
        align: "left",
        formatText: formatDvImages,
        createStyle: ({ approved, total }) => ({"color": approved >= total ? "green" : "red"})
      },
      {
        name: "DV responsible",
        key: "dv_responsible",
        align: "left",
        shouldFilter: true
      },
      {
        name: "Start DV",
        align: "left",
        isButton: true,
        onClick: (row: RowType) => (event) => openDVOutletPage(
          {
            outlet_id: row.outlet_id,
            outlet_name: row.name,
            property_name: row.property_name,
            dv_date: formatDate(row.dv_date),
            oldest_unlabelled: formatDate(row.oldest_unlabelled),
            measurement_start: formatDate(row.measurement_start),
          },
          props.history
        ),
        buttonText: "Go to DV"
      },
    ],
    kitrons: [
      {
        name: "User",
        key: "name",
        align: "left",
        shouldFilter: true
      },
      {
        name: `DV tasks ${lastThreeMonths[0]}`,
        key: "dv_0",
        align: "right",
        shouldFilter: true,
      },
      {
        name: `AA tasks ${lastThreeMonths[0]}`,
        key: "aa_0",
        align: "right",
        shouldFilter: true,
      },
      {
        name: `DV tasks ${lastThreeMonths[1]}`,
        key: "dv_1",
        align: "right",
        shouldFilter: true,
      },
      {
        name: `AA tasks ${lastThreeMonths[1]}`,
        key: "aa_1",
        align: "right",
        shouldFilter: true,
      },
      {
        name: `DV tasks ${lastThreeMonths[2]}`,
        key: "dv_2",
        align: "right",
        shouldFilter: true,
      },
      {
        name: `AA tasks ${lastThreeMonths[2]}`,
        key: "aa_2",
        align: "right",
        shouldFilter: true,
      }
    ],
    agents: [
      {
        name: "Agent",
        key: "name",
        align: "left",
        shouldFilter: true
      },
      {
        name: "Pending verification",
        key: "count",
        align: "right",
        shouldFilter: true,
      },
      {
        name: "Start assessment",
        align: "right",
        shouldFilter: false,
        isButton: true,
        onClick: (row: RowType) => (event) => openDVImagesPage(
          {
            mode: "assess accuracy",
            agent_id: row.id,
            agent_name: row.name,
          },
          props.history
        ),
        buttonText: "Assess accuracy"
      },
    ]
  };

  // Assess accuracy big button event handler
  const onClick = () => openDVImagesPage(
    {
      mode: "assess accuracy",
      agent_id: null,
      agent_name: null
    },
    props.history
  );

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  // Render
  return (
    <div className="animated fadeIn" style={{ height: cssTrick ? "100px" : "100%" }}>
      <h1 ref={titleRef}>Data Validity</h1>

      <div className="customersDVTitle" ref={customersDvRef}>
        <h2>Customers DV</h2>
        <TextField label="DV Responsible 🔎︎" variant="outlined" size="small" value={search} onChange={handleSearch} />
      </div>

      <CustomTable
        dataUrl={"/get-dv-status"}
        columns={columns.customers}
        tableHeight={heights.customers}
        filter={searchFilter}
        defaultSort={"dv_date"}
        colorDVDate={true}
      />

      <div className="accuracyAssessmentTitle" ref={accuracyAssessmentRef}>
        <h2>Accuracy assessment</h2>
        <Button variant="outlined" onClick={onClick}>
          Assess agents' accuracy
        </Button>
      </div>

      <div className="accuracyTablesLayout">
        <div>
          <CustomTable
            dataUrl={"/get-dv-done"}
            columns={columns.kitrons}
            tableHeight={heights.kitrons}
          />
        </div>
        <div>
          <CustomTable
            dataUrl={"/get-pending-verification"}
            columns={columns.agents}
            tableHeight={heights.agents}
          />
        </div>
      </div>
    </div>
  );
};

type OrderType = {
  key: string,
  direction: "asc" | "desc"
};

type FilterKV = {
  key: string,
  value: string
};

type RowType = Record<string, any>;

type CustomTableParams = {
  dataUrl: string,
  columns: Array<CustomTableColumnType>,
  tableHeight: number,
  filter?: FilterKV,
  defaultSort?: string,
  colorDVDate?: boolean
};

const CustomTable = ({ dataUrl, columns, tableHeight, filter, defaultSort, colorDVDate }: CustomTableParams) => {
  const [order, setOrder] = useState<OrderType>({
    key: defaultSort || "name",
    direction: "asc"
  });

  const fetchedData = useFetch(dataUrl);

  const n_days_ago = (n: number): string => {
    const d = new Date(new Date().setDate(new Date().getDate() - n));
    return d.toISOString().substr(0, 10).replaceAll("-", "/");
  };
  const nine_days_ago = n_days_ago(9);
  const seven_days_ago = n_days_ago(7);

  const sortingFunction = (key: string, direction: string) => (a: object, b: object) => {
    const compare = direction === "asc" ? 1 : -1;
    let a_ = a[key];
    let b_ = b[key];

    if (!a_) return 1;
    if (!b_) return -1;

    if (typeof (a_) === "string") a_ = a_.toLowerCase();
    if (typeof (b_) === "string") b_ = b_.toLowerCase();

    return a_ > b_ ? compare : -compare;
  };

  const filterFunction = (filterObject: FilterKV) => (row: RowType) => {
    if (!filterObject) return true;

    const { key, value } = filterObject;
    if (!value) return true;

    return row[key]?.toLowerCase().startsWith(value.toLowerCase());
  };

  const ordered = useMemo(() => {
    if (!fetchedData.loaded)
      return [];
    let result = [...fetchedData.data];
    result = result.sort(sortingFunction(order.key, order.direction));
    return result.filter(filterFunction(filter));
  }, [fetchedData, order, filter]);

  const getSortableHeaderCell = useCallback((column: CustomTableColumnType, index: number) => {
    const { name, key, align, shouldFilter } = column;
    const { key: orderKey, direction } = order;
    const active = key === orderKey;
    const style = {
      backgroundColor: active ? "rgb(234,236,236)" : "#fafafa",
      transition: "background-color 250ms ease",
      padding: "0px"
    };

    if (!shouldFilter) return <TableCell key={index} align={align}>{name}</TableCell>;

    return (
      <TableCell
        align={align}
        style={style}
        key={index}
      >
        <TableSortLabel
          active={active}
          direction={direction}
          style={{
            display: "flex", justifyContent: "space-between", padding: "16px"
          }}
          onClick={() => setOrder({
            key: key,
            direction: orderKey === key ? (direction === "asc" ? "desc" : "asc") : "asc"
          })}
        >
          {name}
        </TableSortLabel>
      </TableCell>
    );
  }, [order]);

  const getRowCell = useCallback((row: RowType, index: number) => {
    const cells = columns.map((column, i) => {
      const { key, align, formatText, createStyle, isButton, onClick, buttonText } = column;
      const text = isButton ? buttonText : (formatText ? formatText(row[key]) : row[key]);
      const style = createStyle ? createStyle(row[key]) : {};

      return <TableCell
        scope="row"
        align={align}
        style={style}
        key={i}
      >
        {isButton ? <Button variant="outlined" onClick={onClick(row)}>{text}</Button> : text}
      </TableCell>;
    });

    const bgColor = colorDVDate ? (row.dv_date < nine_days_ago ? "#fbb" : (row.dv_date < seven_days_ago ? "#fdb" : "#fff")) : "#fff";
    return (
      <TableRow key={index} style={{backgroundColor: bgColor}}>
        {cells}
      </TableRow>
    );
  }, [colorDVDate, columns, nine_days_ago, seven_days_ago]);

  const headers = useMemo(() => columns.map(getSortableHeaderCell), [columns, getSortableHeaderCell]);
  const tableContent = useMemo(() => ordered.map(getRowCell), [ordered, getRowCell]);

  return (
    <TableContainer component={Paper} style={{ "marginBottom": `${MARGINBOTTOM}px`, maxHeight: tableHeight }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {headers}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableContent}
        </TableBody>
      </Table>
      {tableContent.length === 0 && <div className="noResults">{fetchedData.loaded ? "No results" : (fetchedData.error ? "Error loading data" : "Loading...")}</div>}
    </TableContainer>
  );
};

export default DataValidity;

import React from "react";

const TrainingSectionFAQ: React.FC<{
  location: { state: undefined | any },
  history: any
}> = (props) => (
  <div className="animated fadeIn">
      Welcome to the FAQ!
  </div>
);

export default TrainingSectionFAQ;

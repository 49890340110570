const padLeft = (value, len, padding) => {
  padding = String(padding || "0");
  return (padding.repeat(len) + value).slice(-len);
};

export const getDateTime = () => {
  const d = new Date();
  return `${d.getFullYear()}/${padLeft(d.getMonth() + 1, 2)}/${padLeft(d.getDate(), 2)} ` +
    `${padLeft(d.getHours(), 2)}:${padLeft(d.getMinutes(), 2)}:${padLeft(d.getSeconds(), 2)}`;
};

import React, { useEffect, useState } from "react";
import $ from "jquery";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import { getFetch } from "../../utils/fetchUtils";
import moment from "moment";
import { Props, LabelType, DeploymentDataType, FullLabelType } from "./_types";
import { addSpinner, removeSpinner } from "../../utils/helpers";

const LabelCheckerOverview: React.FC<Props> = (props) => {

  const [deploymentsData, setDeploymentsData] = useState<{[name: string]: DeploymentDataType}>({});
  const [labellerData, setLabellerData] = useState<Array<LabelType>>([]);
  const [fullLabelData, setFullLabelData] = useState<Array<FullLabelType>>([]);

  useEffect(() => {
    loadDeployments();
    loadLabels();
  }, []);

  const loadLabels = () => {
    getFetch("/get-labels?active=true", {})
      .then((data) => {
        setFullLabelData(data.allLabels);
      });
  };

  const loadDeployments = () => {
    document.getElementById("overlay").style.display = "block";
    addSpinner($("body"));

    getFetch("/get-deployment-counts", {})
      .then((data) => {
        removeSpinner($("body"));
        document.getElementById("overlay").style.display = "none";
        setDeploymentsData(data.complete_deployments);
        setLabellerData(data.labellers);
      });
  };

  const getFilters = (e) => {
    document.getElementById("overlay").style.display = "block";
    addSpinner($("body"));
    const index = e.target.attributes["data-deployment"].value;
    removeSpinner($("body"));
    document.getElementById("overlay").style.display = "none";
    props.history.push({
      pathname: "/labelChecker/filters",
      state: {
        deploymentData: deploymentsData[index],
        fullLabelData: fullLabelData,
        labellerData: labellerData,
      }
    });
  };

  const generateDeploymentSelection = (active: boolean): JSX.Element | Array<JSX.Element> => {
    const deployments: Array<JSX.Element> = [];
    const rows: Array<{
      outlet: JSX.Element;
      deviceID: string;
      dailyUploads: string;
      colColor: string;
      oldestUnlabelled: String;
    }> = [];

    for (const deployment in deploymentsData) {
      const deploymentData: DeploymentDataType = deploymentsData[deployment];

      if (deploymentData.active) {
        const oldestUnlabelledStr = deploymentData.oldest_unlabelled.split("."),
          oldest_unlabelled = moment([oldestUnlabelledStr[2], parseInt(oldestUnlabelledStr[1]) - 1, oldestUnlabelledStr[0]]),
          current_date = moment(),
          diffDays = current_date.diff(oldest_unlabelled, "days");

        let colColor = "none";
        if (diffDays > 4 && deploymentData.unfinished_count > 250)
          colColor = "rgba(255, 220, 0, 0.5)";
        if (diffDays > 6)
          colColor = "rgba(255, 150, 0, 0.5)";
        if (deploymentData.unfinished_count > 2500)
          colColor = "rgba(225, 0, 0, 0.5)";

        const rowElement = {
          "outlet":
            <button
              key={deploymentData.id}
              data-deployment={deploymentData.id}
              type="button"
              className="deploymentBtn"
              onClick={getFilters}
            >
              {deploymentData.property + " > " + deploymentData.outlet + " > " + deploymentData.area}
            </button>,
          "deviceID": deploymentData.device_id.toString(),
          "dailyUploads": getUploadNumbers(deployment, "deploymentNumbers"),
          "colColor": colColor,
          "oldestUnlabelled": deploymentData.oldest_unlabelled,
        };

        rows.push(rowElement);
      }
      else {
        deployments.push(
          <button
            key={deploymentData.id}
            data-deployment={deploymentData.id}
            type="button"
            className="deploymentBtn" onClick={getFilters}
          >
            {`${deploymentData.property} > ${deploymentData.outlet} > ${deploymentData.area} (Device ID ${deploymentData.device_id}, ${deploymentData.start_date} - ${deploymentData.end_date})`}
          </button>
        );
      }
    }

    if (active) {
      return (
        <Paper className={"labelCheckerTable"}>
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow style={{"fontSize": "15px"}}>
                <TableCell>Outlet Name</TableCell>
                <TableCell align="left">Device ID</TableCell>
                <TableCell align="left">Daily Uploads</TableCell>
                <TableCell align="right">Oldest Unlabelled</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.deviceID} style={{"backgroundColor": row.colColor}}>
                  <TableCell scope="row">{row.outlet}</TableCell>
                  <TableCell align="left">{row.deviceID}</TableCell>
                  <TableCell align="left">{row.dailyUploads}</TableCell>
                  <TableCell align="right">{row.oldestUnlabelled}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      );
    }
    return deployments;

  };

  const getFormattedDate = (date: Date): string => {
    const day = date.getDate() <= 9 ? "0" + date.getDate() : date.getDate(),
      month = date.getMonth() + 1 <= 9 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1,
      year = date.getFullYear();

    return day + "." + month + "." + year;
  };

  const getUploadNumbers = (deployment: string | null, mode: string): string => {
    const last7Days: Array<string> = [];
    const sevenDayObject: { [name: string]: {deviceCount: number, uploadCount: number }} = {};

    const today = new Date(),
      todaym1 = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1),
      todaym2 = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 2),
      todaym3 = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 3),
      todaym4 = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 4),
      todaym5 = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 5),
      todaym6 = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 6);

    const dateStringtoday = getFormattedDate(today),
      dateStringtodaym1 = getFormattedDate(todaym1),
      dateStringtodaym2 = getFormattedDate(todaym2),
      dateStringtodaym3 = getFormattedDate(todaym3),
      dateStringtodaym4 = getFormattedDate(todaym4),
      dateStringtodaym5 = getFormattedDate(todaym5),
      dateStringtodaym6 = getFormattedDate(todaym6);

    last7Days.push(dateStringtoday);
    last7Days.push(dateStringtodaym1);
    last7Days.push(dateStringtodaym2);
    last7Days.push(dateStringtodaym3);
    last7Days.push(dateStringtodaym4);
    last7Days.push(dateStringtodaym5);
    last7Days.push(dateStringtodaym6);

    sevenDayObject[dateStringtoday] = {"deviceCount": 0, "uploadCount": 0};
    sevenDayObject[dateStringtodaym1] = {"deviceCount": 0, "uploadCount": 0};
    sevenDayObject[dateStringtodaym2] = {"deviceCount": 0, "uploadCount": 0};
    sevenDayObject[dateStringtodaym3] = {"deviceCount": 0, "uploadCount": 0};
    sevenDayObject[dateStringtodaym4] = {"deviceCount": 0, "uploadCount": 0};
    sevenDayObject[dateStringtodaym5] = {"deviceCount": 0, "uploadCount": 0};
    sevenDayObject[dateStringtodaym6] = {"deviceCount": 0, "uploadCount": 0};

    switch (mode) {
      case "deploymentNumbers":
        const device_upload_count = deploymentsData[deployment].device_upload_count;
        const finalString: Array<string> = [];

        if (device_upload_count) {
          for (const day in last7Days) {
            const date = last7Days[day];
            if (device_upload_count[date])
              finalString.push(device_upload_count[date].count + "|");
            else
              finalString.push("0 |");
          }

          let countSum = 0;
          let countEntries = 0;
          for (const day in device_upload_count) {
            const count = device_upload_count[day].count;
            if (count > 0) {
              countSum += count;
              countEntries += 1;
            }
          }

          const countAverage = Math.round(countSum / countEntries);
          finalString.push("Avg.: " + countAverage);
          return finalString.join(" ");
        } return "";
      case "totalNumbers":
        for (const deployment in deploymentsData) {
          const device_upload_count_total = deploymentsData[deployment].device_upload_count;

          if (device_upload_count_total) {
            for (const day in last7Days) {
              const date = last7Days[day];
              if (device_upload_count_total[date]) {
                sevenDayObject[date].uploadCount += device_upload_count_total[date].count;
                sevenDayObject[date].deviceCount++;
              }
            }
          }
        }

        const totalFinalString = [];

        totalFinalString.push("Daily Uploads (Active Devices): ");
        let first = true;
        for (const day in sevenDayObject) {
          if (first)
            first = false;
          else
            totalFinalString.push(" | ");
          const upload = sevenDayObject[day].uploadCount;
          const devices = sevenDayObject[day].deviceCount;
          totalFinalString.push(upload + " (" + devices + ") ");
        }

        return totalFinalString.join(" ");
      default:
        return "";
    }
  };

  return (
    <div className="animated fadeIn">
      <h5>{getUploadNumbers(null, "totalNumbers")}</h5>

      <h2>Active: </h2>
      <div>Legend:
        <div className={"colorBox"} style={{"backgroundColor": "rgba(255, 220, 0, 0.5)"}}>Oldest Unlabelled &gt; 4 days &amp; 250+ Unlabelled</div>
        <div className={"colorBox"} style={{"backgroundColor": "rgba(255, 150, 0, 0.5)"}}>Oldest Unlabelled &gt; 6 days</div>
        <div className={"colorBox"} style={{"backgroundColor": "rgba(225, 0, 0, 0.5)"}}>More than 2500 Unlabelled</div>
      </div>
      <div
        style={{
          height: "calc( 73vh - 125px )", width: "100%", overflowY: "scroll", overflowX: "scroll", marginBottom: "10px"
        }}>
        {generateDeploymentSelection(true)}
      </div>
      <h2>Inactive: </h2>
      <div
        style={{
          height: "calc(25vh - 125px)", width: "100%", overflowY: "scroll", overflowX: "scroll"
        }}>
        {generateDeploymentSelection(false)}
      </div>
    </div>
  );
};

export default LabelCheckerOverview;

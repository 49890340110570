import React from "react";
import Loadable from "react-loadable";

// import DefaultLayout from './containers/DefaultLayout';

function Loading() {
  return <div>Loading...</div>;
}

const BatchesOverview = Loadable({
  loader: () => import("./views/BatchesOverview/BatchesOverview"),
  loading: Loading,
});

const Breadcrumbs = Loadable({
  loader: () => import("./views/Base/Breadcrumbs"),
  loading: Loading,
});

const Cards = Loadable({
  loader: () => import("./views/Base/Cards"),
  loading: Loading,
});

const Carousels = Loadable({
  loader: () => import("./views/Base/Carousels"),
  loading: Loading,
});

const Collapses = Loadable({
  loader: () => import("./views/Base/Collapses"),
  loading: Loading,
});

const Dropdowns = Loadable({
  loader: () => import("./views/Base/Dropdowns"),
  loading: Loading,
});

const Forms = Loadable({
  loader: () => import("./views/Base/Forms"),
  loading: Loading,
});

const Jumbotrons = Loadable({
  loader: () => import("./views/Base/Jumbotrons"),
  loading: Loading,
});

const ListGroups = Loadable({
  loader: () => import("./views/Base/ListGroups"),
  loading: Loading,
});

const Navbars = Loadable({
  loader: () => import("./views/Base/Navbars"),
  loading: Loading,
});

const Navs = Loadable({
  loader: () => import("./views/Base/Navs"),
  loading: Loading,
});

const Paginations = Loadable({
  loader: () => import("./views/Base/Paginations"),
  loading: Loading,
});

const Popovers = Loadable({
  loader: () => import("./views/Base/Popovers"),
  loading: Loading,
});

const ProgressBar = Loadable({
  loader: () => import("./views/Base/ProgressBar"),
  loading: Loading,
});

const Switches = Loadable({
  loader: () => import("./views/Base/Switches"),
  loading: Loading,
});

const Tables = Loadable({
  loader: () => import("./views/Base/Tables"),
  loading: Loading,
});

const Tabs = Loadable({
  loader: () => import("./views/Base/Tabs"),
  loading: Loading,
});

const Tooltips = Loadable({
  loader: () => import("./views/Base/Tooltips"),
  loading: Loading,
});

const BrandButtons = Loadable({
  loader: () => import("./views/Buttons/BrandButtons"),
  loading: Loading,
});

const ButtonDropdowns = Loadable({
  loader: () => import("./views/Buttons/ButtonDropdowns"),
  loading: Loading,
});

const ButtonGroups = Loadable({
  loader: () => import("./views/Buttons/ButtonGroups"),
  loading: Loading,
});

const Buttons = Loadable({
  loader: () => import("./views/Buttons/Buttons"),
  loading: Loading,
});

const Charts = Loadable({
  loader: () => import("./views/Charts"),
  loading: Loading,
});

const CoreUIIcons = Loadable({
  loader: () => import("./views/Icons/CoreUIIcons"),
  loading: Loading,
});

const Flags = Loadable({
  loader: () => import("./views/Icons/Flags"),
  loading: Loading,
});

const FontAwesome = Loadable({
  loader: () => import("./views/Icons/FontAwesome"),
  loading: Loading,
});

const SimpleLineIcons = Loadable({
  loader: () => import("./views/Icons/SimpleLineIcons"),
  loading: Loading,
});

const Alerts = Loadable({
  loader: () => import("./views/Notifications/Alerts"),
  loading: Loading,
});

const Badges = Loadable({
  loader: () => import("./views/Notifications/Badges"),
  loading: Loading,
});

const Modals = Loadable({
  loader: () => import("./views/Notifications/Modals"),
  loading: Loading,
});

const Colors = Loadable({
  loader: () => import("./views/Theme/Colors"),
  loading: Loading,
});

const Typography = Loadable({
  loader: () => import("./views/Theme/Typography"),
  loading: Loading,
});

const Widgets = Loadable({
  loader: () => import("./views/Widgets/Widgets"),
  loading: Loading,
});

const Users = Loadable({
  loader: () => import("./views/Users/Users"),
  loading: Loading,
});

const User = Loadable({
  loader: () => import("./views/Users/User"),
  loading: Loading,
});

const Labelling = Loadable({
  loader: () => import("./views/Labelling/Labelling"),
  loading: Loading,
});

const Segmentation = Loadable({
  loader: () => import("./views/Labelling/Segmentation"),
  loading: Loading,
});

const segmentationChecking = Loadable({
  loader: () => import("./views/Labelling/SegmentationChecking"),
  loading: Loading,
});

const controlPanel = Loadable({
  loader: () => import("./views/ControlPanel/ControlPanel"),
  loading: Loading,
});

const trainingSection = Loadable({
  loader: () => import("./views/TrainingSection/TrainingSection"),
  loading: Loading,
});

const trainingSectionFAQ = Loadable({
  loader: () => import("./views/TrainingSection/TrainingSectionFAQ"),
  loading: Loading,
});

const trainingSectionLabelling = Loadable({
  loader: () => import("./views/TrainingSection/TrainingSectionLabelling"),
  loading: Loading,
});

const trainingSectionTutorial = Loadable({
  loader: () => import("./views/TrainingSection/TrainingSectionTutorial"),
  loading: Loading,
});

const labelCheckerOverview = Loadable({
  loader: () => import("./views/LabelChecker/LabelCheckerOverview"),
  loading: Loading,
});

const labelCheckerFilters = Loadable({
  loader: () => import("./views/LabelChecker/LabelCheckerFilters"),
  loading: Loading,
});

const labelCheckerTrasheventList = Loadable({
  loader: () => import("./views/LabelChecker/LabelCheckerTrasheventList"),
  loading: Loading,
});

const labelCheckerLabelling = Loadable({
  loader: () => import("./views/LabelChecker/LabelCheckerLabelling"),
  loading: Loading,
});

const agentFeedback = Loadable({
  loader: () => import("./views/AgentFeedback/AgentFeedback"),
  loading: Loading,
});

const performanceReport = Loadable({
  loader: () => import("./views/PerformanceReport/PerformanceReport"),
  loading: Loading,
});

const dataValidity = Loadable({
  loader: () => import("./views/DataValidity/DataValidity"),
  loading: Loading,
});

const outletDataValidity = Loadable({
  loader: () => import("./views/DataValidity/OutletDataValidity"),
  loading: Loading,
});

const imagesDataValidity = Loadable({
  loader: () => import("./views/DataValidity/ImagesDataValidity"),
  loading: Loading,
});

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  {
    path: "/", exact: true, name: "Home", component: BatchesOverview
  },
  {
    path: "/training", exact: true, name: "Training Section", component: trainingSection
  },
  {
    path: "/training/faq", name: "FAQ", component: trainingSectionFAQ
  },
  {
    path: "/training/labelling", name: "Labelling", component: trainingSectionLabelling
  },
  {
    path: "/training/tutorial", name: "Tutorial", component: trainingSectionTutorial
  },
  {
    path: "/labelChecker", exact: true, name: "Label Checker", component: labelCheckerOverview
  },
  {
    path: "/labelChecker/filters", name: "Filters", component: labelCheckerFilters
  },
  {
    path: "/labelChecker/trasheventList", name: "TrasheventList", component: labelCheckerTrasheventList
  },
  {
    path: "/labelChecker/labelling", name: "Labelling", component: labelCheckerLabelling
  },
  {
    path: "/performanceReport", name: "PerformanceReport", component: performanceReport
  },
  {
    path: "/controlPanel", name: "Control Panel", component: controlPanel
  },
  {
    path: "/labelling", name: "Labelling", component: Labelling
  },
  {
    path: "/segmentation", name: "Segmentation", component: Segmentation
  },
  {
    path: "/segmentation_checking", name: "Segmentation Checking", component: segmentationChecking
  },
  {
    path: "/feedback", name: "Agent Feedback", component: agentFeedback
  },
  {
    path: "/theme", exact: true, name: "Theme", component: Colors
  },
  {
    path: "/theme/colors", name: "Colors", component: Colors
  },
  {
    path: "/theme/typography", name: "Typography", component: Typography
  },
  {
    path: "/base", exact: true, name: "Base", component: Cards
  },
  {
    path: "/base/cards", name: "Cards", component: Cards
  },
  {
    path: "/base/forms", name: "Forms", component: Forms
  },
  {
    path: "/base/switches", name: "Switches", component: Switches
  },
  {
    path: "/base/tables", name: "Tables", component: Tables
  },
  {
    path: "/base/tabs", name: "Tabs", component: Tabs
  },
  {
    path: "/base/breadcrumbs", name: "Breadcrumbs", component: Breadcrumbs
  },
  {
    path: "/base/carousels", name: "Carousel", component: Carousels
  },
  {
    path: "/base/collapses", name: "Collapse", component: Collapses
  },
  {
    path: "/base/dropdowns", name: "Dropdowns", component: Dropdowns
  },
  {
    path: "/base/jumbotrons", name: "Jumbotrons", component: Jumbotrons
  },
  {
    path: "/base/list-groups", name: "List Groups", component: ListGroups
  },
  {
    path: "/base/navbars", name: "Navbars", component: Navbars
  },
  {
    path: "/base/navs", name: "Navs", component: Navs
  },
  {
    path: "/base/paginations", name: "Paginations", component: Paginations
  },
  {
    path: "/base/popovers", name: "Popovers", component: Popovers
  },
  {
    path: "/base/progress-bar", name: "Progress Bar", component: ProgressBar
  },
  {
    path: "/base/tooltips", name: "Tooltips", component: Tooltips
  },
  {
    path: "/buttons", exact: true, name: "Buttons", component: Buttons
  },
  {
    path: "/buttons/buttons", name: "Buttons", component: Buttons
  },
  {
    path: "/buttons/button-dropdowns", name: "Button Dropdowns", component: ButtonDropdowns
  },
  {
    path: "/buttons/button-groups", name: "Button Groups", component: ButtonGroups
  },
  {
    path: "/buttons/brand-buttons", name: "Brand Buttons", component: BrandButtons
  },
  {
    path: "/icons", exact: true, name: "Icons", component: CoreUIIcons
  },
  {
    path: "/icons/coreui-icons", name: "CoreUI Icons", component: CoreUIIcons
  },
  {
    path: "/icons/flags", name: "Flags", component: Flags
  },
  {
    path: "/icons/font-awesome", name: "Font Awesome", component: FontAwesome
  },
  {
    path: "/icons/simple-line-icons", name: "Simple Line Icons", component: SimpleLineIcons
  },
  {
    path: "/notifications", exact: true, name: "Notifications", component: Alerts
  },
  {
    path: "/notifications/alerts", name: "Alerts", component: Alerts
  },
  {
    path: "/notifications/badges", name: "Badges", component: Badges
  },
  {
    path: "/notifications/modals", name: "Modals", component: Modals
  },
  {
    path: "/widgets", name: "Widgets", component: Widgets
  },
  {
    path: "/charts", name: "Charts", component: Charts
  },
  {
    path: "/users", exact: true, name: "Users", component: Users
  },
  {
    path: "/users/:id", exact: true, name: "User Details", component: User
  },
  {
    path: "/dataValidity", exact: true, name: "Data Validity", component: dataValidity
  },
  {
    path: "/dataValidity/outlet", name: "Outlet", component: outletDataValidity
  },
  {
    path: "/dataValidity/images", name: "Images", component: imagesDataValidity
  },
];

export default routes;

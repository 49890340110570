import React, { useState, useEffect } from "react";
import { LabelInfo } from "./types";

type SegmentationLabelsContainerParams = {
  activeColor: number,
  approveFunction?: () => void,
  approveAndSaveFunction?: () => void,
  approveAndSaveDisable?: boolean,
  currentLabel?: {
    label_info: LabelInfo[]
  },
  errorLabelComplete: () => void,
  ignoreFunction?: () => void,
  labelColor: { [key: number]: string },
  labelColorSolid: { [key: number]: string },
  lastComment?: string,
  missingLabel?: () => void,
  missingLabelChecked?: () => void,
  nextButtonString: string,
  nextImageEmpty: () => void,
  nextImageFunction?: () => void,
  noisyImageFunction?: () => void,
  resetUI: number,
  selectActiveColor: (e: number) => void,
  setComment?: (value: string, buttonMessage: boolean) => void,
  setLabel: (type: any) => void,
  setViewMode: (mode: string) => void,
  skipFunction?: () => void,
  toggleLabelInvalid: (e: number) => void
  topDistance: number,
  viewMode: string
};

const SegmentationLabelsContainer = (props: SegmentationLabelsContainerParams) => {
  const [currentLabel, setCurrentLabel] = useState({label_info: props?.currentLabel ? props.currentLabel.label_info : []});
  const [comment, setComment] = useState("");

  useEffect(() => {
    setCurrentLabel({label_info: props?.currentLabel ? props.currentLabel.label_info : []});
  }, [props.currentLabel]);

  const labelComplete = () => {
    if (!currentLabel)
      return false;

    let totalPercentage = 0;
    for (const label in currentLabel.label_info) {
      const currLabel = currentLabel.label_info[label];
      totalPercentage += currLabel.percentage;
      if (currLabel.percentage === 0 || currLabel.id === 0 || currLabel.value === 0 || currLabel.label === "")
        return false;
    }

    if (totalPercentage > 100)
      return false;

    return true;
  };

  const commentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    commentChangeV(e.target.value);
  };

  const commentChangeV = (value: string, buttonMessage: boolean = false) => {
    if (buttonMessage)
      setComment(value + " " + comment);
    else
      setComment(value);
    if (props?.setComment)
      props.setComment(value, buttonMessage);
  };

  const createComponent = () => {
    const labelObj = [];

    for (const element_ in currentLabel.label_info) {
      const element = parseInt(element_);
      const current_label = currentLabel.label_info[element];
      const label_string = current_label.label.split(":");

      const category = label_string[0].charAt(0).toUpperCase() + label_string[0].slice(1);
      const item = label_string[1].charAt(0).toUpperCase() + label_string[1].slice(1);

      let complete_string = "";
      if (label_string[2] === "")
        complete_string = category.concat(" - ").concat(item);
      else
        complete_string = category.concat(" - ").concat(item).concat(" ").concat(label_string[2]);


      complete_string = complete_string.concat(" - ").concat(current_label.avoidable ? "Avoidable" : "Unavoidable");

      labelObj.push(
        <div key={200 + element} id={"segLabel_" + (200 + element).toString()}>
          <button
            type="button"
            style={props.activeColor === element ? {"backgroundColor": props.labelColorSolid[element], "border": "2px solid black"} : {"backgroundColor": props.labelColor[element]}}
            className="btn btn-pill btn-primary selectLabel"
            id={"selectLabel:" + element}
            onClick={() => props.selectActiveColor(element)}>
            {complete_string}
          </button>
          <button
            className="btn btn-pill btn-primary labelInexistent"
            style={current_label.invalid ? {
              "backgroundColor": "rgba(255, 0, 0, 1)", "color": "white", "borderColor": "white"
            } : {"backgroundColor": "rgba(255, 0, 0, 0.2)"}}
            id={"labelInexistent:" + element}
            onClick={() => { props.toggleLabelInvalid(element); }}
          >
            X
          </button>
        </div>
      );

    }

    if (props.lastComment) {
      labelObj.push(
        <>
          <span>Last comment:</span>
          <div className="commentTA">{props.lastComment}</div>
        </>
      );
    }

    if (props.setComment) {
      labelObj.push(
        <div className="commentDiv">
          <span>New comment:</span>
          <textarea className="commentTA" value={comment} onChange={commentChange}></textarea>
          <div>
            <button className="commentPreset" title="Not Accurate Enough" onClick={() => commentChangeV("Mask is not accurate enough.", true)}>NAE</button>
            <button className="commentPreset" title="Not All Masked" onClick={() => commentChangeV("Not all items have been masked.", true)}>NAM</button>
            <button className="commentPreset" title="Add Other" onClick={() => commentChangeV("Add the label 'Other' for other items.", true)}>AO</button>
            <button className="commentPreset" title="Add More Other Labels" onClick={() => commentChangeV("Not all 'Other' items are masked, there are some areas left which need an 'Other' label.", true)}>AMOL</button>
            <button className="commentPreset" title="Missing Spots" onClick={() => commentChangeV("Missing spots inside the mask (make sure the entire mask is coloured).", true)}>MS</button>
            <button className="commentPreset" title="Only Newly Added" onClick={() => commentChangeV("Mask only the part which was newly added and not the entire region (careful: things may have been moved but not newly added).", true)}>ONA</button>
            <button className="commentPreset" title="Wrong Label" onClick={() => commentChangeV("A wrong label was used. Check the colours with the labels.", true)}>WL</button>
            <button className="commentPreset" title="Red X" onClick={() => commentChangeV("Press the red 'X' on the right side of the label to tell the item with this label was not added.", true)}>RX</button>
          </div>
        </div>
      );
    }

    return labelObj;
  };

  const resetCommentAndCall = (fn) => {
    fn();
    setComment("");
  };

  return (
    <div style={{top: props.topDistance + "px"}} className="labelsContainerWindow">
      <h2
        style={{
          textAlign: "left", display: "inline-block", whiteSpace: "nowrap", width: "100%"
        }}><span className={"labelHeader"}>Labels</span></h2>
      {createComponent()}
      {/* { props.labelMissing ? <input id="labelMissing" className="btn btn-primary labelMissing"
              type="button" value={"Label Missing"} onClick={props.labelMissing}/> : '' } */}
      { props.missingLabel ? <input
        id="MissingLabel" disabled={!labelComplete()} className="btn btn-primary missingLabel"
        type="button" value="Missing Label" onClick={props.missingLabel}/> : "" }
      { props.noisyImageFunction ? <input
        id="NoisyImage" disabled={!labelComplete()} className="btn btn-primary noisyImage"
        type="button" value="Noisy Image" onClick={props.noisyImageFunction}/> : "" }
      { props.nextButtonString ? <input
        id="ToNextImage" disabled={!props.nextImageFunction || !labelComplete()} className="btn btn-primary backToNextImage"
        type="button" value={props.nextButtonString} onClick={props.nextImageFunction}/> : "" }
      <div>
        { props.approveFunction ? <input
          id="ApproveImage" className="btn btn-primary segcheck approveImage"
          type="button" value="Approve" onClick={() => resetCommentAndCall(props.approveFunction)}/> : "" }
        { props.approveAndSaveFunction ? <input
          id="ApproveAndSaveImage" className="btn btn-primary segcheck approveImage"
          type="button" value="Save&amp;Approve" disabled={props.approveAndSaveDisable} onClick={() => resetCommentAndCall(props.approveAndSaveFunction)}/> : "" }
        { props.ignoreFunction ? <input
          id="IgnoreImage" className="btn btn-primary segcheck ignoreImage"
          type="button" value="Ignore" onClick={() => resetCommentAndCall(props.ignoreFunction)}/> : "" }
        { props.skipFunction ? <input
          id="SkipImage" className="btn btn-primary segcheck skipImage"
          type="button" value="Skip" onClick={() => resetCommentAndCall(props.skipFunction)}/> : ""}
        { props.missingLabelChecked ? <input
          id="missingLabelChecked" className="btn btn-primary segcheck missingLabelChecked"
          type="button" value="Missing Label" onClick={() => resetCommentAndCall(props.missingLabelChecked)}/> : ""}
      </div>
    </div>
  );
};

export default SegmentationLabelsContainer;

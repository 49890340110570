import React, { useEffect, useMemo, useRef, useContext, useCallback } from "react";
import useFetch from "../../utils/useFetch";
import Select from "react-select";
import { UserContext } from "../../context/userContext";

export const FilterBar = (props) => {
  const { user } = useContext(UserContext);
  const [filters, setFilters] = props.selectedFilters;
  const setSelectedUsernames = props.selectedUsernamesState[1];
  const selectedTemp = useRef(null);
  const [startDate, setStartDate] = props.startDateState;
  const [endDate, setEndDate] = props.endDateState;
  const levels = [
    { value: 0, label: "Level 0" },
    { value: 1, label: "Level 1" },
    { value: 2, label: "Level 2" },
    { value: 3, label: "Level 3" }
  ];

  const levelReset = useRef(false);
  const selectRefLevel = useRef();
  const companyReset = useRef(false);
  const selectRefCompany = useRef();

  const agentUsernames = useFetch(`/agent-performance/get-filter-info?start_date=${startDate}&end_date=${endDate}`, {
    extract: (data) => data.map((item) => ({
      id: item.id,
      label: item.username,
      value: item.username,
      company: item.company_identifier,
      user_level: item.data.labellingInfo.level
    }))
  });

  const filterByLevelAndCompany = useCallback((level, company) => {
    let users = agentUsernames.data;
    if (level) {
      const levels = level.map((item) => item.value);
      if (users)
        users = users.filter((user) => levels.includes(user.user_level));

    }
    if (company) {
      const comps = company.map((item) => item.label);
      if (users)
        users = users.filter((user) => comps.includes(user.company));

    }
    return users;
  }, [agentUsernames.data]);

  useEffect(() => {
    const users = filterByLevelAndCompany(filters.level, filters.company);
    if (filters.level || filters.company) {
      // Respect and preserve the filters when reloading due to date-range change
      setSelectedUsernames(users);
      selectedTemp.current = users;
      if (!(!selectRefLevel.current || typeof(selectRefLevel.current) === "undefined")) {
        levelReset.current = true;
        selectRefLevel.current.select.setValue(filters.level);
      }
      if (!(!selectRefCompany.current || typeof(selectRefCompany.current) === "undefined")) {
        companyReset.current = true;
        selectRefCompany.current.select.setValue(filters.company);
      }
    }
    else if (user.labellingInfo.level < 3) {
      // Agent level < 3 can only have access to their personal data
      selectedTemp.current = [{
        id: user.id,
        label: user.username,
        value: user.username,
        company: user.company,
        user_level: user.labellingInfo.level
      }];
    }
    else if (selectedTemp.current === null) {
      // Also, respect the selected agents
      setSelectedUsernames(users);
    }
  }, [agentUsernames.data, filterByLevelAndCompany, filters.company, filters.level,
    setSelectedUsernames, user.company, user.id, user.labellingInfo.level, user.username]);

  const companies = useMemo(() => {
    let comps = [...new Set((agentUsernames.data || []).map((u) => u.company).filter((c) => Boolean(c)))];
    comps = comps.map((c, i) => ({ value: i, label: c }));
    return comps;
  }, [agentUsernames.data]);

  if (agentUsernames.error)
    return <div className="report-cards-container">Error: {agentUsernames.error}</div>;

  if (!agentUsernames.loaded)
    return <div className="report-cards-container">Loading...</div>;

  const customStylesUsernames = {
    control: (provided) => {
      const maxHeight = "85px";
      const overflowY = "auto";
      const borderRadius = "0px";
      const minHeight = "40px";
      return {
        ...provided, maxHeight, overflowY, borderRadius, minHeight
      };
    },
    indicatorsContainer: (provided) => {
      const maxHeight = "85px";
      const position = "sticky";
      const top = "0px";
      return {
        ...provided, maxHeight, position, top
      };
    }
  };

  const handleUsernameChange = (username) => {
    setSelectedUsernames(username ? username : agentUsernames.data);
    selectedTemp.current = username;
    levelReset.current = true;
    selectRefLevel.current.select.clearValue();
    companyReset.current = true;
    selectRefCompany.current.select.clearValue();
    setFilters({level: null, company: null});
  };

  const handleLevelChange = (level) => {
    if (levelReset.current)
      levelReset.current = false;
    else {
      const users = filterByLevelAndCompany(level, filters.company);
      setSelectedUsernames(users);
      selectedTemp.current = level || filters.company ? users : null;
    }
    setFilters({...filters, level: level});
  };

  const handleCompanyChange = (company) => {
    if (companyReset.current)
      companyReset.current = false;
    else {
      const users = filterByLevelAndCompany(filters.level, company);
      setSelectedUsernames(users);
      selectedTemp.current = filters.level || company ? users : null;
    }
    setFilters({...filters, company: company});
  };

  return (
    <>
      <div className="input-group md-4">
        {/* Username Filter */}
        <div className="input-group-prepend">
          <span className="input-group-text" id="inputGroup-sizing-default">
            Username
          </span>
        </div>
        <Select
          name="selectViewmode"
          key="selectViewmodeUsernames"
          isMulti
          isClearable
          options={agentUsernames.data}
          placeholder="All"
          onChange={handleUsernameChange}
          value={selectedTemp.current}
          closeMenuOnSelect={false}
          styles={customStylesUsernames}
          className="basic-multi-select liveCountViewmodeSelect select-form"
          classNamePrefix="select"
          isDisabled={user.labellingInfo.level < 3}
        />

        {/* User Level Filter */}
        <div className="input-group-prepend leftMargin25">
          <span className="input-group-text" id="inputGroup-sizing-default">
            Level
          </span>
        </div>
        <Select
          name="selectViewmode"
          key="selectViewmode"
          isMulti
          isClearable
          onChange={handleLevelChange}
          options={levels}
          ref={selectRefLevel}
          value={
            user.labellingInfo.level < 3
              ? [{ value: user.labellingInfo.level, label: `Level ${user.labellingInfo.level}` }]
              : filters.level
          }
          placeholder="Any"
          closeMenuOnSelect={false}
          className="basic-multi-select liveCountViewmodeSelect select-form"
          classNamePrefix="select"
          isDisabled={user.labellingInfo.level < 3}
        />

        {/* Company Filter */}
        <div className="input-group-prepend leftMargin25">
          <span className="input-group-text" id="inputGroup-sizing-default">
            Company
          </span>
        </div>
        <Select
          name="selectViewmode"
          key="selectViewmodeCompany"
          isMulti
          isClearable
          onChange={handleCompanyChange}
          options={companies}
          ref={selectRefCompany}
          value={
            user.labellingInfo.level < 3
              ? [{ value: user.company === "KITRO" ? 1 : 0, label: user.company }]
              : filters.company
          }
          placeholder="Any"
          closeMenuOnSelect={false}
          className="basic-multi-select liveCountViewmodeSelect select-form"
          classNamePrefix="select"
          isDisabled={user.labellingInfo.level < 3}
        />

        {/* Start Date Filter */}
        <div className="input-group-prepend leftMargin25">
          <span className="input-group-text" id="inputGroup-sizing-default">
            Start Date
          </span>
        </div>
        <input
          id={"startDate"}
          value={startDate}
          onChange={(e) => { (e.target.value <= endDate) && setStartDate(e.target.value); }}
          type="date"
          className="form-control"
          aria-label="Default"
          aria-describedby="inputGroup-sizing-default"
        />

        {/* End Date Filter */}
        <div className="input-group-prepend leftMargin25">
          <span className="input-group-text" id="inputGroup-sizing-default">
            End Date
          </span>
        </div>
        <input
          id={"endDate"}
          value={endDate}
          onChange={(e) => { (e.target.value >= startDate) && setEndDate(e.target.value); }}
          type="date"
          className="form-control"
          aria-label="Default"
          aria-describedby="inputGroup-sizing-default"
        />
      </div>
    </>
  );
};

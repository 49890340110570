import React from "react";

import ImageContainer from "../Labelling/ImageContainer";
import LabelsContainer from "../Labelling/LabelsContainer";
import { TrasheventListType, FullLabelType } from "./_types";

import { HotKeys } from "react-hotkeys";

const map = {
  "saveChanges": "alt+enter",
  "abortChanges": "alt+e",
};


const LabelCheckerLabelling: React.FC<{
  addAndSaveImageChanges?: (labelSelection: TrasheventListType["final_labels"], errors: Array<number>) => void,
  imageURL: string,
  labelData: {[label: string]: FullLabelType},
  labellingEnabled: boolean,
  prevImageURL: string,
  requestClose?: () => void,
  saveImageChanges?: (labelSelection: TrasheventListType["final_labels"], errors: Array<number>) => void,
  title: string,
  trasheventData: TrasheventListType | null
}> = (props) => {

  const getFilteredLabels = (): Array<{id: number, percentage: number, transformed: boolean}> => {
    if (!props.trasheventData)
      return null;
    return props.trasheventData.final_labels.filter((fl) => fl.id in props.labelData);
  };

  const handlers: {[label: string] : (e: KeyboardEvent) => void} = {"abortChanges": (e) => { e.preventDefault(); props.requestClose(); }};

  return (
    <HotKeys keyMap={map} handlers={handlers}>
      <div className="animated fadeIn">
        {props.title && <h1 style={{marginTop: "-15px"}}>{props.title}</h1>}
        <div
          style={{
            marginBottom: "5px", fontWeight: "bold", fontSize: "1rem", maxWidth: "64%"
          }}>
          <span style={{marginRight: "18%"}}>
            {"Device ID: " + (props.trasheventData ? props.trasheventData.device_id : "")}
          </span>
          <span>
            {"Added Weight: " + (props.trasheventData ? props.trasheventData.weight : "") + "g"}
          </span>
        </div>
        <ImageContainer
          prevImage={props.prevImageURL || null}
          currImage={props.imageURL || null}
        />
        {props.labellingEnabled
          ? <LabelsContainer
            topDistance={68}
            nextImageFunction={props.saveImageChanges}
            addAndNextFunction={props.addAndSaveImageChanges}
            nextButtonString={"Save Changes"}
            addAndNextButtonString="Save Changes &amp; Add To Exam"
            currentLabel={getFilteredLabels()}
            currentError={props.trasheventData ? props.trasheventData.final_errors : null}
            currentImageData={props.trasheventData}
            labels={Object.values(props.labelData)}
          />
          : null }
      </div>
    </HotKeys>
  );
};

export default LabelCheckerLabelling;

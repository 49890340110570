import React, {useMemo, useRef, useState, useCallback} from "react";
import {HotKeys} from "react-hotkeys";

import LabelSelector, { compareLabels } from "../../containers/LabelSelector";
import type { Label, LabelSelection } from "../../containers/LabelSelector";

type LabelsContainerParams = {
  nextImageFunction: (ls: LabelSelection[]) => void,
  addAndNextFunction?: (ls: LabelSelection[]) => void,
  currentLabel?: LabelSelection[],
  labels: Label[],
  topDistance: number,
  nextButtonString: string,
  addAndNextButtonString?: string
};

// TODO: missing `currentImageData` here, and then forward it to `LabelSelector` as well!
const LabelsContainer = ({nextImageFunction, addAndNextFunction, currentLabel, labels, topDistance, nextButtonString, addAndNextButtonString}: LabelsContainerParams) => {
  const lsRef = useRef<React.ElementRef<typeof LabelSelector>>(null);
  const [resetLabels, setResetLabels] = useState(0);
  const [labelComplete, setLabelComplete] = useState(false);

  const nextImage = () => {
    if (lsRef.current.labelComplete()) {
      nextImageFunction(lsRef.current.selection());
      setResetLabels(resetLabels + 1);
    }
  };

  const addAndNext = () => {
    if (lsRef.current.labelComplete())
      addAndNextFunction(lsRef.current.selection());
  };

  const labelChangeCbk = useCallback(() => {
    setLabelComplete(lsRef.current.labelComplete());
  }, []);
  const errorChangeCbk = useCallback(() => {
    // console.log(lsRef.current.errors());
  }, []);

  const sortedLabels = useMemo(() => Object.values(labels).sort(compareLabels), [labels]);

  return <HotKeys keyMap={{"nextImage": "alt+enter"}} handlers={{"nextImage": nextImage}}>
    <div style={{top: `${topDistance}px`, padding: "2%"}} className="labelsContainerWindow" id="labelsContainerWindow">
      <LabelSelector initialLabels={currentLabel} allLabels={sortedLabels} reset={resetLabels} labelChangeCbk={labelChangeCbk} errorChangeCbk={errorChangeCbk} ref={lsRef} />
      <input
        id="ToNextImage" disabled={!labelComplete} className="btn btn-primary backToNextImage"
        type="button" value={nextButtonString} onClick={nextImage}/>
      {addAndNextFunction
        ? <input
          id="AddToExamAndToNextImage" disabled={!labelComplete} className="btn btn-primary addAndNextImage"
          type="button" value={addAndNextButtonString} onClick={addAndNext}/>
        : ""}
    </div>
  </HotKeys>;
};

export default LabelsContainer;

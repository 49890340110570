import React, { useMemo } from "react";
import useFetch from "../../utils/useFetch";

import ReactTooltip from "react-tooltip";
import TableWithSorting from "./TableWithSorting";

const WorkingTimeTable = ({agentIDs, startDate, endDate}) => {
  const data = useFetch(`/agent-performance/working-time-table-data?agent=${agentIDs}&start_date=${startDate}&end_date=${endDate}`);
  const headCells = useMemo(() => [
    {
      id: "username", numeric: false, label: "Agent Name"
    },
    {
      id: "labelling_level", numeric: true, label: "Labelling Level"
    },
    {
      id: "hours_labelling", numeric: true, label: "Hours Labelling"
    },
    {
      id: "hours_segmentation", numeric: true, label: "Hours Segmentation"
    },
    {
      id: "hours_worked", numeric: true, label: "Hours Worked"
    },
    {
      id: "days_per_week", numeric: true, label: "Days per Week"
    },
    {
      id: "hours_per_day", numeric: true, label: "Hours per Day"
    },
    {
      id: "tasks_per_hour", numeric: true, label: "Tasks per Hour"
    },
  ], []);

  if (data.error)
    return <div>Failed to load: {data.error}</div>;
  if (!data.loaded)
    return <div>Loading...</div>;

  const rows = Object.keys(data.data).filter((k) => k !== "total").map((k) => data.data[k]);
  return <TableWithSorting headCells={headCells} rowData={rows} lastRow={data.data.total} />;
};

const WorkingTimeTooltip = () => <ReactTooltip id="workingTimeTableTip" event="click" globalEventOff="click" place="right" type="info" offset={{"top": -50}} arrowColor="transparent">
  <div className="headline">Definitions</div>
  <div className="subheadline">Hours Labelling</div>
  <div className="text">Number of distinct hours during which agent labelled or checked at least one event.</div>
  <div className="subheadline">Hours Segmentation</div>
  <div className="text">Number of distinct hours during which agent segmented or checked at least one event.</div>
  <div className="subheadline">Hours Worked</div>
  <div className="text">Number of distinct hours during which agent worked on either labelling or segmentation.</div>
  <div className="subheadline">Days per Week</div>
  <div className="text">Average number of active days (days with at least one task) per week.</div>
  <div className="subheadline">Hours per Day</div>
  <div className="text">Hours worked divided by number of active days.</div>
  <div className="subheadline">Tasks per Hour</div>
  <div className="text">Total labelling and segmentation tasks divided by hours worked.</div>
</ReactTooltip>;

export { WorkingTimeTable, WorkingTimeTooltip };

import React, { Component } from "react";
import { fetchAuthSession } from "aws-amplify/auth";
import Select from "react-select";
import $ from "jquery";
import { getFetch } from "../../utils/fetchUtils";

class ControlPanelDeviceUpdate extends Component {
  constructor(props) {
    super(props);

    this.getOptions = this.getOptions.bind(this);
    this.getData = this.getData.bind(this);
    this.updateDevice = this.updateDevice.bind(this);

    this.state = {
      username: "",
      devices: {},
      softwareVersions: {},
      versionSelection: {},
      deviceSelection: [],
    };
  }

  componentDidMount() {
    fetchAuthSession()
      .then((data) => {
        this.setState({username: data.tokens.idToken.payload["cognito:username"]});
      }
      )
      .then(() => {
        this.getData();
      });
  }

  getOptions(type) {
    const options = [];

    switch (type) {
      case "software":
        let max = 0;
        const softwareVersions = this.state.softwareVersions;
        for (const version in softwareVersions) {
          options.push({value: softwareVersions[version].version, label: "Version " + softwareVersions[version].version});
          if (version >= max)
            max = version;

        }

        break;
      case "device":
        const devices = this.state.devices;

        for (const device in devices)
          options.push({value: devices[device].id, label: "Device " + devices[device].id});

        break;
      default:
    }

    return options;
  }

  getData() {
    getFetch("/get-control-panel-data?data=deviceUpdate", {})
      .then((data) => {
        this.setState({
          softwareVersions: data.softwareVersions,
          devices: data.devices,
        });
      });
  }

  updateDevice() {
    const deviceSelection = this.state.deviceSelection;
    const versionSelection = this.state.versionSelection;

    if (this.state.deviceSelection.length === 0 || Object.keys(this.state.versionSelection).length === 0) {
      $("#response").text("Error!");
      $("#response").css("color", "red");
      setTimeout(function () {
        $("#response").text("");
      }, 1500);
      return;
    }

    const postData = {
      "type": "deviceUpdate",
      "data": {
        "deviceSelection": deviceSelection,
        "versionSelection": versionSelection,
      }
    };

    getFetch("/add-control-panel-data", {method: "POST", data: postData})
      .then(() => {
        $("#response").text("Success!");
        $("#response").css("color", "green");
        setTimeout(function () {
          $("#response").text("");
        }, 1500);
      }, () => {
        $("#response").text("Error!");
        $("#response").css("color", "red");
        setTimeout(function () {
          $("#response").text("");
        }, 1500);
      })
      .then(() => {
        this.setState({
          deviceSelection: [],
          versionSelection: {}
        });
      });
  }

  render() {
    return (
      <div className="animated fadeIn">
        <div className="input-group mb-3">
          <div className="input-group-prepend">
            <span className="input-group-text" id="inputGroup-sizing-default">Software Version</span>
          </div>
          <Select key={0} className={"deviceUpdateSWVersionSelect"} options={this.getOptions("software")} value={this.state.versionSelection} onChange={(selection) => this.setState({versionSelection: selection})} />
          <button style={{position: "absolute", right: "25px"}} type="button" className="btn btn-primary addDataBtn" onClick={this.updateDevice}>Update Device</button>
        </div>
        <div className="input-group mb-3">
          <div className="input-group-prepend">
            <span className="input-group-text" id="inputGroup-sizing-default">Device</span>
          </div>
          <Select key={1} className={"deviceUpdateDeviceSelect"} isMulti options={this.getOptions("device")} value={this.state.deviceSelection} onChange={(selection) => this.setState({deviceSelection: selection})} />
        </div>
      </div>
    );
  }
}

export default ControlPanelDeviceUpdate;

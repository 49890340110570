import React, { useMemo } from "react";
import useFetch from "../../utils/useFetch";

import ReactTooltip from "react-tooltip";
import {Chart} from "react-charts";
import TableWithSorting from "./TableWithSorting";

const LabellingAccuracyTable = ({agentIDs, startDate, endDate}) => {
  const data = useFetch(`/agent-performance/labelling-accuracy-table-data?agent=${agentIDs}&start_date=${startDate}&end_date=${endDate}`);
  const headCells = useMemo(() => [
    {
      id: "username", numeric: false, label: "Agent Name"
    },
    {
      id: "accuracy", numeric: true, label: "Accuracy"
    },
    {
      id: "tasks_accounted", numeric: true, label: "Accounted Tasks"
    },
    {
      id: "tasks_correct", numeric: true, label: "Correct Tasks"
    },
    {
      id: "best_daily_accuracy", numeric: true, label: "Best Daily Accuracy"
    },
    {
      id: "worst_daily_accuracy", numeric: true, label: "Worst Daily Accuracy"
    },
    {
      id: "days_above", numeric: true, label: "Days Above Threshold"
    },
    {
      id: "days_below", numeric: true, label: "Days Below Threshold"
    },
  ], []);

  if (data.error)
    return <div>Failed to load: {data.error}</div>;
  if (!data.loaded)
    return <div>Loading...</div>;

  const rows = Object.keys(data.data).filter((k) => k !== "global").map((k) => data.data[k]);
  return <TableWithSorting headCells={headCells} rowData={rows} lastRow={data.data.global} />;
};

const LabellingAccuracyLineChart = ({agentIDs, startDate, endDate}) => {
  const data = useFetch(`/agent-performance/labelling-accuracy-chart-data?agent=${agentIDs}&start_date=${startDate}&end_date=${endDate}`);

  const chart_data = useMemo(() => [
    {
      label: "Average Rolling Accuracy",
      data: data.loaded ? data.data.map((rec) => ({ primary: Date.parse(rec.primary), secondary: rec.secondary })) : []
    }
  ], [data]);

  const chart_axes = useMemo(() => [
    {
      primary: true, type: "time", position: "bottom"
    },
    {
      type: "linear", position: "left", hardMin: 0, hardMax: 100
    },
  ], []);

  if (!data.loaded || !data.data.length)
    return null;

  return <div
    style={{
      width: "100%", height: "22em", margin: "1em 0 2em", overflow: "hidden"
    }}>
    <h2 style={{textAlign: "center"}}>Accuracy evolution over time</h2>
    <div style={{height: "20em"}}>
      <Chart data={chart_data} axes={chart_axes} tooltip />
    </div>
  </div>;
};

const LabellingAccuracyTooltip = () => <ReactTooltip id="labellingAccuracyTableTip" event="click" globalEventOff="click" place="right" type="info" offset={{"top": -50}} arrowColor="transparent">
  <div className="headline">Definitions</div>
  <div className="subheadline">Accounted Task</div>
  <div className="text">Number of tasks for which the trashevent went through Data Validity.<br></br>Only these are accounted in the accuracy calculation.</div>
  <div className="subheadline">Correct Task</div>
  <div className="text">Number of tasks among the Accounted Tasks for which the final labels are the same as the labels set by the agent.</div>
  <div className="subheadline">Accuracy</div>
  <div className="text">Percentage of Correct Tasks among the Accounted Tasks.</div>
  <div className="subheadline">Days Above/Below Threshold</div>
  <div className="text">Number of days spent above or below the accuracy target set for each agent level.
    <ul>
      <li>Level 1: 60%</li>
      <li>Level 2: 70%</li>
      <li>Level 3: 80%</li>
    </ul>
  </div>
  <div className="subheadline">Average Rolling Accuracy</div>
  <div className="text">Accuracy calculated on tasks from the current day and the 6 prior days. Its value is averaged over all the selected agents</div>
</ReactTooltip>;

export {
  LabellingAccuracyTable, LabellingAccuracyLineChart, LabellingAccuracyTooltip
};

import React from "react";
import {printLabel, Label} from "../../containers/LabelSelector/LabelSelector";
import "./style.scss";

type LabelInfoData = {
  id: number,
  percentage: number,
  transformed: boolean | null
};

const getTransformedString = (lid: LabelInfoData, labels: {[label_id: number]: Label}): string | null => {
  if (lid.transformed === true)
    return labels[lid.id].transformed;
  else if (lid.transformed === false)
    return labels[lid.id].untransformed;
  return null;
};

type LabelingData = {
  errors: number[],
  labeler_name: string,
  label_info: LabelInfoData[]
};

type LabelingParams = {
  data: LabelingData,
  onSelect: () => void,
  labels: {[label_id: number]: Label}
};

const Labeling = ({data, onSelect, labels}: LabelingParams) => <div className="labeling-container">
  <div>
    <div className="labeling-select-button" onClick={onSelect}>select</div>
    <b>Labeled by: {data.labeler_name}</b>
  </div>
  <ul className="labeling-list">
    {data.label_info.map((lid, i) => <li key={i} className="labeling-list">
      {printLabel(labels[lid.id], 0, " / ")} ({lid.percentage}%) {getTransformedString(lid, labels)}
    </li>)}
  </ul>
</div>;

type CurrentImageData = {
  labels: {[labeler_id: number]: LabelingData}
};

type LabelingSelectorParams = {
  onSelect: (labeler_id: number) => void,
  currentImageData: CurrentImageData,
  labels: {[label_id: number]: Label}
};

const LabelingSelector = ({onSelect, currentImageData, labels}: LabelingSelectorParams) => <div style={{top: "159px", padding: "2%"}} className="labelsContainerWindow" id="labelsContainerWindow">
  {Object.keys(currentImageData.labels).map((labeler_id) => <Labeling key={labeler_id} data={currentImageData.labels[labeler_id]} onSelect={() => onSelect(parseInt(labeler_id))} labels={labels} />)}
</div>;

export default LabelingSelector;

import React, { useState, useEffect, useMemo } from "react";
import $ from "jquery";
import { addSpinner, removeSpinner } from "../../utils/helpers";

import Select, { createFilter } from "react-select";
import {FixedSizeList as List} from "react-window";

import { getFetch } from "../../utils/fetchUtils";
import { printLabel, Label } from "../../containers/LabelSelector/LabelSelector";
import { Props, LabelType, DeploymentDataType } from "./_types";

type menuListProps = {
  options,
  children,
  maxHeight,
  getValue
}

const MenuList: React.FC<menuListProps> = (props) => {
  const height = 35;
  const [value] = props.getValue();

  return (
    <List
      height={props.maxHeight}
      itemCount={props.children.length}
      itemSize={height}
      initialScrollOffset={props.options.indexOf(value) * height}
    >
      {({ index, style }) => <div style={style}>{props.children[index]}</div>}
    </List>
  );
};

const LabelCheckerFilters: React.FC<Props> = (props) => {
  const [labelSelection, setLabelSelection] = useState<Array<LabelType>>([]);
  const [weightSelection, setWeightSelection] = useState<number>(0);
  const [consensusOptionSelection, setConsensusOptionSelection] = useState<null | LabelType>(null);
  const [weightPrefixSelection, setWeightPrefixSelection] = useState<LabelType>({
    "id": 4, "label": "above or equal to", "value": "aeq"
  });
  const [labellerSelection, setLabellerSelection] = useState<Array<LabelType>>([]);
  const [trasheventList, setTrasheventList] = useState({});
  const [goLabelChecker, setGoLabelChecker] = useState<boolean>(false);

  const deploymentData: DeploymentDataType = useMemo(() => props.history.location.state ? props.history.location.state.deploymentData : {},
    [props.history]);
  const labelData: Array<LabelType> = props.history.location.state
    ? Object.values(props.history.location.state.fullLabelData).map(
      (fl: Label & {value: string}) => ({
        id: fl.id, label: printLabel(fl), value: fl.value
      })
    )
    : [];
  const username: string = props.history.location.state ? props.history.location.state.username : "";
  const labellerData: Array<LabelType> = props.history.location.state ? props.history.location.state.labellerData : [];

  useEffect(() => {
    if (!props.history.location.state)
      props.history.push("/labelChecker");
  }, [props.history]);

  useEffect(() => {
    if (goLabelChecker) {
      const startDate = $("#deploymentStartDate").val();
      const endDate = $("#deploymentEndDate").val();
      try {
        removeSpinner($("body"));
        document.getElementById("overlay").style.display = "none";
        props.history.push({
          pathname: "/labelChecker/trasheventList",
          state: {
            deploymentData: deploymentData,
            username: username,
            labelSelection: labelSelection,
            startDateSelection: startDate,
            endDateSelection: endDate,
            weightPrefixSelection: weightPrefixSelection,
            weightSelection: weightSelection,
            labellerSelection: labellerSelection,
            consensusOptionSelection: consensusOptionSelection,
            trasheventList: trasheventList,
          }
        });
      }
      catch (error) {
      }
    }
  }, [trasheventList, consensusOptionSelection, deploymentData, goLabelChecker, labelSelection,
    labellerSelection, props.history, username, weightPrefixSelection, weightSelection]);

  const getTrashevents = (): void => {
    const startDate = $("#deploymentStartDate").val();
    const endDate = $("#deploymentEndDate").val();

    document.getElementById("overlay").style.display = "block";
    addSpinner($("body"));

    const postData = {
      deploymentData: deploymentData,
      labels: labelSelection,
      startDate: startDate,
      endDate: endDate,
      weightPrefix: weightPrefixSelection.id,
      weight: weightSelection,
      labellers: labellerSelection,
      consensus: consensusOptionSelection,
    };

    getFetch("/get-filtered-trashevents", { data: postData, method: "POST"})
      .then((data) => {
        setTrasheventList(data.trashevents);
        setGoLabelChecker(true);
      });
  };

  let dvDate: null | Date = null;
  let unlabelledDate: null | Date = null;

  if (deploymentData.data_validity) {
    const dvDateStr = deploymentData.data_validity.split(".");
    dvDate = new Date(parseInt(dvDateStr[2]), parseInt(dvDateStr[1]) - 1, parseInt(dvDateStr[0]), 12, 0, 0);
  }
  if (deploymentData.oldest_unlabelled) {
    const unlabelledDateStr = deploymentData.oldest_unlabelled.split(".");
    unlabelledDate = new Date(parseInt(unlabelledDateStr[2]), parseInt(unlabelledDateStr[1]) - 1, parseInt(unlabelledDateStr[0]), 12, 0, 0);
  }

  return (
    <div className="animated fadeIn">
      <h1>Deployment: {deploymentData.customer + " > " + deploymentData.property + " > " + deploymentData.outlet + " > " + deploymentData.area }</h1>
      <h2>Filters: </h2>
      <div style={{paddingBottom: "10px"}}>
        <h3>Labels:</h3>
        <Select
          name='labels'
          key='labelSelect'
          options={labelData} value={labelSelection}
          onChange={(selection) => setLabelSelection(selection)}
          filterOption={createFilter({ignoreAccents: false})}
          components={{ MenuList }}
          closeMenuOnSelect={false}
          isMulti
          className="basic-multi-select labelCheckerFilterLabelSelect"
          classNamePrefix="select"
        />
      </div>
      <div style={{paddingBottom: "10px"}}>
        <h3>Daterange</h3>
        <div className="input-group mb-3">
          <div className="input-group-prepend">
            <span className="input-group-text" id="inputGroup-sizing-default">Start date</span>
          </div>
          <input id={"deploymentStartDate"} defaultValue={dvDate ? dvDate.toISOString().substr(0, 10) : null} type="date" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default"/>
          <div className="input-group-prepend">
            <span className="input-group-text" id="inputGroup-sizing-default">End Date</span>
          </div>
          <input id={"deploymentEndDate"} defaultValue={unlabelledDate ? unlabelledDate.toISOString().substr(0, 10) : null} type="date" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default"/>
        </div>
      </div>
      <div style={{paddingBottom: "10px"}}>
        <div
          style={{
            paddingBottom: "10px", display: "inline-block", minWidth: "35%"
          }}>
          <h3>Weight</h3>
          <div className="input-group mb-3">
            <Select
              key='weightOptionSelect'
              name="weightPrefix"
              options={[
                {
                  "id": 0, "label": "equal to", "value": "eq"
                },
                {
                  "id": 1, "label": "strictly below", "value": "b"
                },
                {
                  "id": 2, "label": "strictly above", "value": "a"
                },
                {
                  "id": 3, "label": "below or equal to", "value": "beq"
                },
                {
                  "id": 4, "label": "above or equal to", "value": "aeq"
                }
              ]}
              className="basic-multi-select inputWeightSelect"
              classNamePrefix="select"
              value={weightPrefixSelection}
              onChange={(selection) => setWeightPrefixSelection(selection)}
              isOptionDisabled={(option) => option.disabled}
            />
            <input
              id={"weight"}
              type="number"
              className="form-control"
              aria-label="Default"
              aria-describedby="inputGroup-sizing-default"
              onChange={(event) => setWeightSelection(parseInt(event.target.value))}
              value={weightSelection}
            />
            <div className="input-group-append">
              <span className="input-group-text" id="inputGroup-sizing-default">grams</span>
            </div>
          </div>
        </div>
        <div
          style={{
            paddingBottom: "10px", paddingLeft: "25px", display: "inline-block", minWidth: "25%"
          }}>
          <h3>Consensus</h3>
          <Select
            key='consensusOptionSelect'
            name="consensusOption"
            options={[{
              "id": 0, "label": "Matched", "value": "cm"
            }, {
              "id": 1, "label": "No Match", "value": "nm"
            }]}
            className="basic-multi-select consensusOptionSelect"
            classNamePrefix="select"
            value={consensusOptionSelection}
            onChange={(selection) => setConsensusOptionSelection(selection)}
            isOptionDisabled={(option) => option.disabled}
            isClearable={true}
          />
        </div>
        <div
          style={{
            paddingBottom: "10px", display: "inline-block", minWidth: "40%", paddingLeft: "25px"
          }}>
          <h3>Labeller</h3>
          <Select
            key='labellerSelect'
            name="weightPrefix"
            options={labellerData}
            className="basic-multi-select labellerSelect"
            classNamePrefix="select"
            value={labellerSelection}
            onChange={(selection) => setLabellerSelection(selection)}
            isOptionDisabled={(option) => option.disabled}
            isMulti
            closeMenuOnSelect={false}
          />
        </div>
      </div>
      <button type="button" className="btn btn-primary backToOverview" onClick={() => { props.history.push("/labelChecker"); }}>Back to Deployment Overview</button>
      <button type="button" className="btn btn-primary addDataBtnDeployment" onClick={getTrashevents}>Apply Filters</button>

    </div>
  );
};

export default LabelCheckerFilters;

import React, { useState, useEffect } from "react";

import Modal from "react-modal";

import { LazyLoadImage } from "react-lazy-load-image-component";
import LabelCheckerLabelling from "./LabelCheckerLabelling";
import { printLabel } from "../../containers/LabelSelector/LabelSelector";
import { getFetch, postData } from "../../utils/fetchUtils";
import { Props, FullLabelType, DeploymentDataType, TrasheventListType, LabellingModal } from "./_types";

const AWS = require("aws-sdk");
const s3 = new AWS.S3({
  accessKeyId: "AKIA4ZN2FXCSXF3DZJSD", secretAccessKey: "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", useAccelerateEndpoint: false, signatureVersion: "v4", region: "eu-central-1"
});

const customModalStyles = {
  content: {
    width: "165vh",
    height: "92vh",
    left: "50%",
    top: "53%",
    transform: "translate(-50%, -50%)",
  }
};

const LabelCheckerTrasheventList: React.FC<Props> = (props) => {

  const [trasheventList, setTrasheventList] = useState<{[label: string]: TrasheventListType}>({}),
    [currDisplay, setCurrDisplay] = useState<{[label: string]: boolean}>({}),
    [imageURLs, setImageURLs] = useState<{[label: string]: string}>({}),
    [labellingModal, setLabellingModal] = useState<LabellingModal>({
      trasheventData: null,
      title: "",
      imageURL: null,
      prevImageURL: null,
      isOpen: false
    }),
    [fullLabelData, setFullLabelData] = useState<{[label: string]: FullLabelType}>(null);

  const deploymentData: DeploymentDataType = props.history.location.state ? props.history.location.state.deploymentData : {};

  useEffect(() => {
    loadLabels();
    Modal.setAppElement("body");

    if (!props.history.location.state) {
      props.history.push("/labelChecker");
      return;
    }

    const trasheventList = props.history.location.state.trasheventList;
    const currDisplay = {};
    for (const trashevent in trasheventList)
      currDisplay[trashevent] = false;

    setCurrDisplay(currDisplay);
    setFullLabelData(props.history.location.state.fullLabelData);
    setTrasheventList(trasheventList);
  }, [props.history]);

  const getImageURL = (imageKey: string): string => {
    if (Object.prototype.hasOwnProperty.call(imageURLs, imageKey))
      return imageURLs[imageKey];

    const imageUrl = getURL(imageKey);
    setImageURLs((prevImageUrl) => ({
      ...prevImageUrl,
      [imageKey]: imageUrl
    }));

    return imageUrl;

  };

  const getURL = (imageKey: string): string => s3.getSignedUrl("getObject", {Bucket: "kitroengine-s3", Key: imageKey});

  const getDateString = (datetime: string): string => {
    const myDate = new Date(datetime);
    return myDate.toString().split("GMT")[0];
  };

  const switchImage = (id: number, status: boolean): void => {
    const currDisplayTemp = currDisplay;
    currDisplayTemp[id] = status;
    setCurrDisplay((prevDisplay) => ({
      ...prevDisplay,
      [id]: status
    }));
  };

  const generateTrasheventList = (): Array<JSX.Element> => {
    const trashevents = [];

    const isAvoidable = (id: number) => {
      if (fullLabelData)
        return id in fullLabelData ? fullLabelData[id].avoidable === "edible" : false;
      return true;
    };
    const labelName = (id: number) => {
      if (fullLabelData)
        return id in fullLabelData ? printLabel(fullLabelData[id]) : "inactive label";
      return "N/A";
    };

    for (const trashevent in trasheventList) {
      const finalLabels = [];
      const consensusLabels = [];
      const trasheventData = trasheventList[trashevent];

      for (const label in trasheventData.final_labels) {
        const labelTrashevent = trasheventData.final_labels[label];
        finalLabels.push(
          <div key={labelTrashevent.id.toString()} style={{color: isAvoidable(labelTrashevent.id) ? "green" : "red"}}>
            {`${labelName(labelTrashevent.id)} - ${labelTrashevent.percentage}%`}
          </div>
        );
      }

      for (const label in trasheventData.consensus_labels) {
        const labelTrashevent = trasheventData.consensus_labels[label];
        consensusLabels.push(
          <div key={labelTrashevent.id.toString() + labelTrashevent.username} style={{color: isAvoidable(labelTrashevent.id) ? "green" : "red"}}>
            {labelName(labelTrashevent.id) + " - " + labelTrashevent.username}
          </div>
        );
      }

      let consensusMsg = "Consensus Match";
      if (!trasheventData.consensus_matched) {
        consensusMsg = trasheventData.consensus_conflict
          ? "Consensus Conflict:" + trasheventData.cons_conflict_checked_by
          : "Directly Labelled by " + trasheventData.final_username;
      }

      let errorAlgMsg = "valid";
      if (trasheventData.error_tag !== 0)
        errorAlgMsg = trasheventData.error_tag === 1 ? "error" : "-";

      trashevents.push(
        <div key={trashevent} onClick={() => toggleLabellingModal(true, trasheventData)} className={"labelCheckerTrasheventItem"}>
          <div style={{ position: "relative" }}>
            <div
              className={"imageOverlay"}
              onMouseOver={() => switchImage(trasheventData.trashevent_id, true)}
              onMouseLeave={() => switchImage(trasheventData.trashevent_id, false)}
            >
              {currDisplay[trasheventData.trashevent_id] ? "Prev" : "Curr"}
            </div>
            <LazyLoadImage
              id="0"
              src={currDisplay[trasheventData.trashevent_id] ? getImageURL(trasheventData.prev_image_id) : getImageURL(trasheventData.image_id)}
              key={trasheventData.trashevent_id}
              className="labelCheckerImageContainer photo"
            />
          </div>
          <div className="labelCheckerImageInformation" onClick={() => toggleLabellingModal(true, trasheventData, false)}>
            <h3>{getDateString(trasheventData.datetime)}</h3>
            <div style={{ display: "block" }}>{`Device ID: ${trasheventData.device_id} - Weight: ${trasheventData.weight}g`}</div>
            <div style={{ display: "block" }}>{finalLabels}</div>
            <h3
              style={{
                display: "block", marginBottom: "0px", fontSize: "16px"
              }}>
              {consensusMsg}
            </h3>
            <div style={{ display: "block" }}>{trasheventData.consensus_conflict ? consensusLabels : ""}</div>
            <div style={{ display: "block" }}>Error Alg: {errorAlgMsg}</div>
          </div>
        </div>
      );
    }

    return trashevents;
  };

  const toggleLabellingModal = (
    status: boolean, data: TrasheventListType | null, saved: boolean = false, add_to_exam: boolean = false
  ): void => {

    if (status) {
      setLabellingModal({
        trasheventData: data,
        title: getDateString(data.datetime),
        imageURL: getImageURL(data.image_id),
        prevImageURL: getImageURL(data.prev_image_id),
        isOpen: true
      });
    }
    else {
      if (saved)
        relabelTrashevent(data, add_to_exam);

      setLabellingModal({
        trasheventData: null,
        title: "",
        imageURL: null,
        prevImageURL: null,
        isOpen: false
      });
    }
  };

  const relabelTrashevent = (trasheventData: TrasheventListType, add_to_exam: boolean): void => {

    getFetch("/relabel-trashevent", {method: "POST", data: {"trasheventData": trasheventData}})
      .then((response) => {
        if (response.status === 202)
          alert("An error occured");
        else if (response.status === 200) {
          if (add_to_exam) {
            postData("/training/exam/question", {
              data: {trashevent_id: trasheventData.trashevent_id}, api: null, method: "POST"
            });
          }

        }
      });
    setTrasheventList((prevList) => ({
      ...prevList,
      [trasheventData.trashevent_id]: trasheventData
    }));
  };

  const loadLabels = (): void => {
    getFetch("/get-labels?active=true", {})
      .then((data) => {
        setFullLabelData(data.allLabels);
      });
  };

  const saveImageChanges = (labelSelection: TrasheventListType["final_labels"], errors: Array<number>): void => {
    const newTrasheventData = {
      ...labellingModal.trasheventData,
      final_labels: labelSelection,
      final_errors: errors
    };

    setLabellingModal((prevLabellingModal) => ({
      ...prevLabellingModal,
      trasheventData: {
        ...prevLabellingModal.trasheventData,
        final_labels: labelSelection,
        final_errors: errors
      }
    }));

    toggleLabellingModal(false, newTrasheventData, true);
  };

  const addAndSaveImageChanges = (labelSelection: TrasheventListType["final_labels"], errors: Array<number>): void => {
    const newTrasheventData = {
      ...labellingModal.trasheventData,
      final_labels: labelSelection,
      final_errors: errors
    };

    setLabellingModal((prevLabellingModal) => ({
      ...prevLabellingModal,
      trasheventData: {
        ...prevLabellingModal.trasheventData,
        final_labels: labelSelection,
        final_errors: errors
      }
    }));

    toggleLabellingModal(false, newTrasheventData, true, true);
  };

  return (
    <div id='labelCheckerBody' className="animated fadeIn">
      <h1>Deployment: {deploymentData.customer + " > " + deploymentData.property + " > " + deploymentData.outlet + " > " + deploymentData.area }</h1>
      <Modal
        isOpen={labellingModal.isOpen}
        onRequestClose={() => toggleLabellingModal(false, null, false)}
        contentLabel="LabellingModal"
        style={customModalStyles}
      >
        <LabelCheckerLabelling
          trasheventData={labellingModal.trasheventData}
          imageURL={labellingModal.imageURL}
          prevImageURL={labellingModal.prevImageURL}
          title={labellingModal.title}
          labelData={fullLabelData}
          saveImageChanges={saveImageChanges}
          addAndSaveImageChanges={addAndSaveImageChanges}
          labellingEnabled={true}
          requestClose={() => toggleLabellingModal(false, null, false)}/>
      </Modal>
      <div className={"labelCheckerTrasheventList"}>
        {generateTrasheventList()}
      </div>
    </div>
  );
};

export default LabelCheckerTrasheventList;

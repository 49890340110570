import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { Container } from "reactstrap";

import { AppAside, AppBreadcrumb, AppHeader} from "@coreui/react";
import routes from "../../routes";
import DefaultAside from "./DefaultAside";
import DefaultHeader from "./DefaultHeader";
import Labelling from "../../views/Labelling/Labelling";
import Segmentation from "../../views/Labelling/Segmentation";
import SegmentationChecking from "../../views/Labelling/SegmentationChecking";
import TrainingQuiz from "../../views/TrainingQuiz/TrainingQuiz";
import BatchesOverview from "../../views/BatchesOverview/BatchesOverview";
import BatchDone from "../../views/BatchesOverview/BatchDone";
import ControlPanel from "../../views/ControlPanel/ControlPanel";
import TrainingSection from "../../views/TrainingSection/TrainingSection";
import TrainingSectionFAQ from "../../views/TrainingSection/TrainingSectionFAQ";
import TrainingSectionTutorial from "../../views/TrainingSection/TrainingSectionTutorial";
import TrainingSectionLabelling from "../../views/TrainingSection/TrainingSectionLabelling";
import LabelCheckerOverview from "../../views/LabelChecker/LabelCheckerOverview";
import LabelCheckerFilters from "../../views/LabelChecker/LabelCheckerFilters";
import LabelCheckerTrasheventList from "../../views/LabelChecker/LabelCheckerTrasheventList";
import LabelCheckerLabelling from "../../views/LabelChecker/LabelCheckerLabelling";
import AgentFeedback from "../../views/AgentFeedback/AgentFeedback";
import PerformanceReport from "../../views/PerformanceReport/PerformanceReport";
import DataValidity from "../../views/DataValidity/DataValidity";
import OutletDataValidity from "../../views/DataValidity/OutletDataValidity";
import ImagesDataValidity from "../../views/DataValidity/ImagesDataValidity";

import User from "../../views/Users/User";
import $ from "jquery";

const DefaultLayout = () => {
  useEffect(() => {
    $(".check-icon").hide();
  });

  const myBatchesOverview = (props) => (
    <BatchesOverview
      {...props}
    />
  );

  const myLabellingComponent = (props) => (
    <Labelling
      {...props}
    />
  );

  const mySegmentationComponent = (props) => (
    <Segmentation
      {...props}
    />
  );

  const mySegmentationCheckingComponent = (props) => (
    <SegmentationChecking
      {...props}
    />
  );

  return (
    <div className="app">
      <AppHeader fixed style={{"backgroundColor": "#4E5F62"}}>
        <DefaultHeader/>
      </AppHeader>
      <div className="app-body">
        <main className="main">
          <div className="success-checkmark">
            <div className="check-icon">
              <span className="icon-line line-tip"></span>
              <span className="icon-line line-long"></span>
              <div className="icon-circle"></div>
              <div className="icon-fix"></div>
            </div>
          </div>
          <div id="overlay"></div>
          <AppBreadcrumb appRoutes={routes}/>
          <Container fluid style={{"height": "90%"}}>
            <Switch>
              <Route exact path="/" component={myBatchesOverview}/>
              <Route path="/labelling" component={myLabellingComponent}/>
              <Route path="/segmentation" component={mySegmentationComponent}/>
              <Route path="/segmentation_checking" component={mySegmentationCheckingComponent}/>
              <Route path="/user" component={User}/>
              <Route path="/batchDone" component={BatchDone}/>
              <Route path="/controlPanel" component={ControlPanel}/>
              <Route path="/feedback" component={AgentFeedback}/>
              <Route exact path="/training" component={TrainingSection}/>
              <Route path="/training/faq" component={TrainingSectionFAQ}/>
              <Route path="/training/tutorial" component={TrainingSectionTutorial}/>
              <Route path="/training/labelling" component={TrainingSectionLabelling}/>
              <Route path="/training/quiz" component={TrainingQuiz}/>
              <Route path="/training/level-1-exam" component={TrainingQuiz}/>
              <Route exact path="/labelChecker" component={LabelCheckerOverview}/>
              <Route path="/labelChecker/filters" component={LabelCheckerFilters}/>
              <Route path="/labelChecker/trasheventList" component={LabelCheckerTrasheventList}/>
              <Route path="/labelChecker/labelling" component={LabelCheckerLabelling}/>
              <Route exact path="/performanceReport" component={PerformanceReport}/>
              <Route exact path="/dataValidity" component={DataValidity}/>
              <Route path="/dataValidity/outlet" component={OutletDataValidity}/>
              <Route path="/dataValidity/images" component={ImagesDataValidity}/>
            </Switch>
          </Container>
        </main>
        <AppAside fixed>
          <DefaultAside />
        </AppAside>
      </div>
    </div>
  );
};


export default DefaultLayout;

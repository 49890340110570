
import { fetchAuthSession } from "aws-amplify/auth";

export const getFetchResp = async (url, { data, auth = true, api = null, headers = {}, method = "GET" }) => {
  if (!(["GET", "POST", "PUT", "PATCH", "DELETE"].includes(method)))
    throw new Error("Invalid method provided.");

  const opts = {
    method: method,
    mode: "cors",
    headers: { "Content-Type": "application/json", ...headers }
  };

  if (["POST", "PUT", "PATCH"].includes(method)) {
    if (data === undefined)
      throw new Error("Missing data!");
    opts.body = typeof(data) == "object" ? JSON.stringify(data) : data;
  }

  if (auth) {
    const session = await fetchAuthSession();
    opts.headers.Authorization = session.tokens.idToken.toString();
  }

  const full_url = (api || global.api_url) + url;
  return await fetch(full_url, opts); // eslint-disable-line no-return-await
};

export const getFetch = async (url, { data = {}, auth = true, api = undefined, headers = {}, method = "GET", signal = undefined }) => {
  if (!(["GET", "POST", "PUT", "PATCH", "DELETE"].includes(method)))
    throw new Error("Invalid method provided.");

  const opts = {
    method: method,
    mode: "cors",
    signal: signal,
    headers: { "Content-Type": "application/json", ...headers }
  };

  if (["POST", "PUT", "PATCH"].includes(method)) {
    if (data === undefined)
      throw new Error("Missing data!");
    opts.body = typeof(data) == "object" ? JSON.stringify(data) : data;
  }

  if (auth) {
    const session = await fetchAuthSession();
    opts.headers.Authorization = session.tokens.idToken.toString();
  }

  const full_url = (api || global.api_url) + url;
  const resp = await fetch(full_url, opts);
  const rdata = await resp.json();

  if (resp.ok)
    return rdata;
  throw new Error(rdata.Message);
};

// :param url is relative path from API
// :param options object may contain:
// - data - literal data to send; if sending object, make sure to JSON.stringify() [required]
// - auth - boolean, defaults to true (needs to be explicitly turned off)
// - api - provide a different API base URL, other than config.API_URL
// - method - one of ['POST', 'PUT', 'PATCH'], default 'POST'
// :returns response data, or raises Error
export const postData = async (url, { data, auth = true, api, method = "POST" }) => {
  if (data === undefined)
    throw new Error("Missing data!");

  if (!(["POST", "PUT", "PATCH"].includes(method)))
    throw new Error("Invalid method provided.");

  const opts = {
    method: method,
    mode: "cors",
    body: typeof(data) == "object" ? JSON.stringify(data) : data,
    headers: { "Content-Type": "application/json" }
  };
  if (auth) {
    const session = await fetchAuthSession();
    opts.headers.Authorization = session.tokens.idToken.toString();
  }

  const full_url = (api || global.api_url) + url;
  const resp = await fetch(full_url, opts);
  const rdata = await resp.json();

  if (resp.ok)
    return rdata;
  throw new Error(rdata.Message);
};

import React, { useEffect, useState } from "react";
import { getFetch } from "../../utils/fetchUtils";
import useFetch from "../../utils/useFetch";
import Select from "react-select";


const ControlPanelSoftwareVersion = () => {
  const softwareVersions = useFetch("/get-control-panel-data?data=softwareVersions");
  const [selectedVersion, setSelectedVersion] = useState({});
  const [projectDetails, setProjectDetails] = useState({});


  useEffect(() => {
    if (softwareVersions.data)
      setSelectedVersion(softwareVersions.data.softwareVersions[Object.keys(softwareVersions.data.softwareVersions)[0]]);

  }, [softwareVersions]);

  useEffect(() => {
    const projects = selectedVersion.containers;
    const promises = [];

    for (const project in projects) {
      const container = projects[project];
      const GATEWAY_URL = ["https://gitlab.com/api/v4/projects/" + container.project_id.toString() + "/repository/commits?all=true&per_page=1000&order=default"];
      promises.push(
        fetch(GATEWAY_URL, {
          method: "GET",
          mode: "cors",
          headers: {"PRIVATE-TOKEN": "LNPzYSchWpfusySCL4rQ"}
        })
          .then((response) => (response.json()))
          .then((data) => {
            if (data?.error)
              container.commits = [];
            else {
              data.sort((commit_a, commit_b) => commit_a.committed_date < commit_b.committed_date ? 1 : -1);

              const dateOptions = {
                year: "numeric", month: "numeric", day: "numeric", hour: "numeric", minute: "numeric", second: "numeric"
              };
              container.commits = data.map((commit) => {
                const date = new Date(Date.parse(commit.committed_date));
                const newDate = date.toLocaleDateString("de-DE", dateOptions);
                return {value: commit.id, label: commit.short_id + " - " + newDate};
              });
              const selectedCommit = container.commits.filter((commit) => commit.value === container.sha);
              container.selected_commit = selectedCommit.length ? selectedCommit[0] : {};
            }
          })
      );
    }

    Promise.all(promises)
      .then(() => {
        setProjectDetails(projects);
      });
  }, [selectedVersion]);

  const getSoftwareVersionOptions = () => {
    if (softwareVersions.data) {
      const versions = softwareVersions.data.softwareVersions;
      const options = Object.keys(versions).map((version_id) => {
        const option = {
          "value": version_id,
          "label": versions[version_id].version_label
        };
        return option;
      });
      return options;
    }
    return {};

  };

  const getSelectedVersion = () => {
    if (selectedVersion) {
      return {
        value: selectedVersion.version_id,
        label: selectedVersion.version_label
      };
    }
  };

  const setSelectedCommit = (selection, project) => {
    const projects = projectDetails;
    projects[project].selected_commit.value = selection.value;
    projects[project].selected_commit.label = selection.label;
    setProjectDetails(projects);
  };

  const createSoftwareVersion = () => {
    const commitSelection = {};
    Object.keys(projectDetails).forEach((project) => {
      commitSelection[projectDetails[project].project_id] = projectDetails[project].selected_commit;
    });
    const postData = {
      "type": "softwareVersions",
      "data": {"commitSelection": commitSelection}
    };

    getFetch("/add-control-panel-data", { method: "POST", data: postData });
  };

  const ContainersList = () => {
    const containerList = [];
    const containerListOutside = [];

    containerListOutside.push(<div key={0}><h3 className={"data_address"}>Name</h3><h3 className={"data_address"}>GitLab ProjectID</h3><h3 className={"data_address"}>Commits</h3></div>);

    for (const project in projectDetails) {
      const projectData = projectDetails[project];

      containerList.push(
        <div key={project} style={{borderBottom: "1px solid black", marginTop: "3px"}}>
          <div className={"data_address"}>{projectData.container_name}</div>
          <div className={"data_address"}>{projectData.project_id}</div>
          <Select
            className={"deviceVersionCommits"}
            menuPlacement="auto"
            options={projectData.commits}
            value={projectData.selected_commit}
            onChange={(selection) => setSelectedCommit(selection, project) } />
        </div>);
    }

    containerListOutside.push(<div
      key={1} style={{
        height: "calc( 70vh - 125px )", width: "100%", overflowY: "scroll", overflowX: "scroll",
      }}>{containerList}</div>);
    return containerListOutside;
  };


  return (
    <div className="animated fadeIn">
      <div>
        <div>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text" id="inputGroup-sizing-default">Select version preset</span>
            </div>
            {selectedVersion ? <Select className={"deviceVersionSelect"} options={getSoftwareVersionOptions()} value={getSelectedVersion()} onChange={(selection) => setSelectedVersion(softwareVersions.data.softwareVersions[selection.value])} /> : null}
            <button style={{position: "absolute", right: "25px"}} type="button" className="btn btn-primary addDataBtn" onClick={createSoftwareVersion}>Create Software Version</button>
          </div>
        </div>
      </div>
      {selectedVersion ? <div>
        <h4>Containers:</h4>
        <div>{projectDetails ? <ContainersList/> : null}</div>
      </div> : null}
    </div>
  );
};

export default ControlPanelSoftwareVersion;

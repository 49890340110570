import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import useFetch from "../../utils/useFetch";

import "./LiveCountComponent.scss";

type LiveCountResponse = {
  to_consensus: number,
  directly_labelled: number,
  consensus_checking: number,
  segmentation: number,
  segmentation_checking: number,
  max_tasks: number,
  task_count: number
};

type LiveCountComponentHandle = {
  reload: () => void
};

type LiveCountComponentParams = {
  role: string,
  loadCallback?: (maxedTasks: boolean) => void
};

const LiveCountComponent: React.ForwardRefRenderFunction<LiveCountComponentHandle, LiveCountComponentParams> = ({role, loadCallback}, ref) => {
  const [reloads, setReloads] = useState(0);
  const liveCount = useFetch<LiveCountResponse>(`/agent-performance/live-count?reloads=${reloads}`);

  useImperativeHandle(ref, () => ({reload: () => setReloads(reloads + 1)}));

  useEffect(() => {
    if (loadCallback) {
      if (liveCount.loaded)
        loadCallback(liveCount.data.task_count >= liveCount.data.max_tasks);
      else if (liveCount.error)
        // if there's any error in the live count endpoint, incl. timeout, pretend all's OK
        loadCallback(false);
    }
  }, [liveCount, loadCallback]);

  if (!liveCount.loaded)
    return <></>;

  if (role === "labelling") {
    return <>
      <div className="live-count-container" title={`Today's ${role} tasks count`}>
        <b>{liveCount.data.to_consensus + liveCount.data.directly_labelled}</b>
      </div>
      <div className="live-count-container" title={`Today's ${role} checking count`}>
        <b>{liveCount.data.consensus_checking}</b>
      </div>
      <div className="live-count-container live-count-total" title={"Today's total task count (labelling task count + labelling checking task count + segmentation task count * 15 + segmentation checking task count * 2.7)"}>
        <b>{liveCount.data.task_count}</b>
      </div>
    </>;
  }
  return <>
    <div className="live-count-container" title={`Today's ${role} tasks count`}>
      <b>{liveCount.data.segmentation}</b>
    </div>
    <div className="live-count-container" title={`Today's ${role} checking count`}>
      <b>{liveCount.data.segmentation_checking}</b>
    </div>
    <div className="live-count-container live-count-total" title={"Today's total task count (labelling task count + labelling checking task count + segmentation task count * 15 + segmentation checking task count * 2.7)"}>
      <b>{liveCount.data.task_count}</b>
    </div>
  </>;
};

export default forwardRef(LiveCountComponent);
export type { LiveCountComponentHandle };

import React, { ReactNode, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { DVStatus, AgentsDV, LabelList, ImagesDV, Task } from "./_types";
import { Label } from "../../containers/LabelSelector";
import { Divider, Radio } from "@material-ui/core";
import { printLabel } from "../../containers/LabelSelector/LabelSelector";

const TaskCard: React.FC<{
    task: Task,
    labelmap: LabelList | null,
    state: (ImagesDV | AgentsDV),
    setStatus: (status: DVStatus) => void,
    handleClickOnImage: () => void,
    urls: {
        currURL: string,
        prevURL: string,
    }
    edited: boolean,
    selectionDisabled: boolean
}> = (props) => {
  // State: logic
  const [prev, setPrev] = useState<boolean>(false);

  // Sub-components: radio buttons
  const RadioButton: React.FC<{target: DVStatus, alternative: DVStatus, text: string}> = ({target, alternative, text}) => (
    <div className="radio" onClick={() => { if (!props.selectionDisabled) props.setStatus(props.task.status === target ? alternative : target); }}>
      <Radio disabled={props.selectionDisabled} color="primary" checked={props.task.status === target} />
      {text}
    </div>);

  // Generators: labellings
  const generateLabelList = (): ReactNode[] => {
    const list = [];

    if (!props.labelmap) {
      list.push(<p key={0}>Loading...</p>);
      return list;
    }

    for (const labelInfo of props.task.labels) {
      const label: Label = props.labelmap[labelInfo.id];
      list.push(
        label
          ? <p key={labelInfo.id}>• {printLabel(label, -1, " > ")} <b> {label.description} - {labelInfo.percentage}%</b></p>
          : <p key={labelInfo.id}>Label missing: {labelInfo.id}</p>
      );
    }

    const errors = props.task.errors.length;
    if (errors) list.push(<p key={"errors"}>{`⚠ ${errors} ${errors === 1 ? "error" : "errors"}`}</p>);

    return list;
  };

  // Generators: radio buttons
  const generateRadioButtons = (): JSX.Element[] => {
    if ((props.state.mode === "check images" || props.state.mode === "review images") && props.state.dv_type === "outliers")
      return [<RadioButton key="approve" target="approved" alternative="pending" text="Approved" />];

    return [
      <RadioButton key="approve" target="approved" alternative="skipped" text="Approve" />,
      <RadioButton key="reject" target="rejected" alternative="skipped" text="Reject" />
    ];
  };


  // Render
  return (
    <div className="task">
      <div
        className="taskImage"
        onMouseEnter={() => setPrev(true)}
        onMouseLeave={() => setPrev(false)}
        onClick={props.handleClickOnImage}
      >
        <LazyLoadImage
          id="0"
          src={prev ? props.urls.prevURL : props.urls.currURL}
          key={props.task.id}
          className="lazyLoaderTask photo"
        />
        <div style={{opacity: prev ? "0.9" : "0"}} className="indicator">Previous</div>
        <div style={{opacity: props.edited ? "0.9" : "0"}} className="indicatorEdited">Edited</div>
      </div>
      <h4>{new Date(props.task.datetime).toString().split("GMT")[0]}</h4>
      <h4>Device ID: {props.task.device_id} - Weight: <b>{props.task.weight}g</b></h4>
      <Divider />
      <div className="labels">{generateLabelList()}</div>
      <div className="radioButtons">{props.state && generateRadioButtons()}</div>
    </div>
  );
};

export default TaskCard;

import React, { useMemo, useState } from "react";

import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";

const descendingComparator = (a, b, orderBy, numeric) => {
  if (numeric)
    return b[orderBy] - a[orderBy];
  return b[orderBy].toLocaleLowerCase().localeCompare(a[orderBy].toLocaleLowerCase());
};

const getComparator = (order, orderBy, numeric) => order === "desc"
  ? (a, b) => descendingComparator(a, b, orderBy, numeric)
  : (a, b) => -descendingComparator(a, b, orderBy, numeric);

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

const EnhancedTableHead = ({ headCells, order, orderBy, onRequestSort }) => {
  const createSortHandler = (property, numeric) => (event) => {
    onRequestSort(event, property, numeric);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "center" : "left"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{
              fontWeight: "bold", backgroundColor: "#ddd", borderBottom: "2px solid black"
            }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id, headCell.numeric)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const rowFromData = (headCells, row, style = {}, index = null) => <TableRow key={index ? index : 0} hover>
  {headCells.map((headCell) => <TableCell key={headCell.id} align={headCell.numeric ? "center" : "left"} style={style}>{row[headCell.id]}</TableCell>)}
</TableRow>;

const TableWithSorting = ({headCells, rowData, lastRow}) => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(headCells[0].id);
  const [numericSort, setNumericSort] = useState(headCells[0].numeric);

  const handleRequestSort = (event, property, numeric) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setNumericSort(numeric);
  };

  const rows = useMemo(() => stableSort(rowData, getComparator(order, orderBy, numericSort)), [order, orderBy, rowData, numericSort]);

  return <div style={{marginBottom: "0.5em"}}>
    <Paper>
      <Table
        size="small"
        aria-labelledby="tableTitle"
        aria-label="enhanced table"
      >
        <EnhancedTableHead
          headCells={headCells}
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
        />
        <TableBody>
          {rows.map((row, index) => rowFromData(headCells, row, {}, index))}
          {lastRow ? rowFromData(headCells, lastRow, {
            fontWeight: "bold", backgroundColor: "#ffd", borderTop: "2px solid black"
          }) : null}
        </TableBody>
      </Table>
    </Paper>
  </div>;
};

export default TableWithSorting;
